.hrmTimeCard_main_div {
    padding: 0 5rem;
    margin-top: 1.5vh;
}

.timeCardDate {
    font-size: 1rem;
    color: black;
    margin: 0;
}

.hrmtimedetailCard_right_div {

    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
}

.hrmtimedetailCard_right_div svg {
    height: 2rem;
    margin-bottom: 1rem;
}

.hrmtimedetailCard_div {
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    background: #f9f9f9;
}

.timeCard-heading {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 19% 19% calc(45% - 4rem) 10% 7%;
    gap: 1rem;
    margin-bottom: 1rem;
    color: black
}


.timeCard {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 19% 19% calc(45% - 4rem) 10% 7%;
    gap: 1rem;
}

.timeCardTextarea textarea {
    width: 100%;
    padding: 0.3rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;

}

.timeCardTimeinput {
    width: 60%;
    padding: 0.2rem;

    border: 1px solid #ccc;
    border-radius: 0.3rem;
}

.sc-kpDprT .hWSxjN {
    z-index: 9999 !important;
}

.nrmTimecardButtonSubmit {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 1rem 0 0rem 0;
}

.nrmTimecardButtonSubmit button {
    padding: 0.4rem 1.4rem;
    background-color: #1011a2;
    border-radius: 0.3rem;
    width: 3;
    border: none;
    color: white;
    /* margin-top: 0.3rem; */
}

.trashcard {
    color: black;
    display: flex;
    /* justify-content: center; */
    gap: 1.5rem;
}

.trashcard svg {
    height: 1.4rem;
}

.timecardHeading {
    display: flex;
    justify-content: right;
    /* width: 100%; */
    margin: 0.5rem 0;
}

.timecardHeading div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeCard-show-div {
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.timeCard-show-div div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeCard-show-div p {
    margin: 0;
}

.timeCard-show-div svg {
    height: 2rem;
}

.rotate-icon {
    transform: rotate(90deg);
}

.selectedTimeCard {
    background-color: #f2f2f2;
}

.timcard-hidden-div {
    padding: 0.5rem 3rem;
    border: 1px solid #ccc;
    margin-top: 0.1rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.timeCard-drop-div {

    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 20% 20% calc(50% - 4rem) 10%;
    gap: 1rem;
    margin-bottom: 1rem;
}

.edit-btn-time-card {
    font-size: 0.8rem;
    padding: 0 0.5rem;
    border: none;
    background-color: #1011a2;
    color: white;
}

.addTimecard-btn-class {
    font-size: 1rem;
    padding: 0.3rem 2.5rem;
    color: white;
    border-radius: 0.3rem;
    border: none;
    background-color: rgb(42, 192, 28);
}

#hrmmyTextarea:active,
#hrmmyTextarea:focus {
    outline: none !important;
    border: 2.4px solid #2684ff;
    /* box-shadow: 0 0 10px #719ECE; */
}

#hrmmytimeInput:active,
#hrmmytimeInput:focus {
    outline: none !important;
    border: 2.4px solid #2684ff;
    /* box-shadow: 0 0 10px #719ECE; */
}

.approvedBtn {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    color: white;
    border-radius: 0.3rem;
    border: none;
    background-color: rgb(42, 192, 28);
}

.messageofHrm div {

    z-index: 99999;
    padding: 0.7rem 2rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.messageofHrm .success {
    background-color: #4caf50;
}

.messageofHrm .error {
    background-color: red;
}

.trForTimeCard th:nth-child(1) {
    width: 25%;
}

.trForTimeCard th:nth-child(2) {
    width: 15%;
}

.trForTimeCard th:nth-child(3) {
    width: 15%;
}

.trForTimeCard th:nth-child(4) {
    width: 45%;
}

@media (max-width: 768px) {
    .hrmTimeCard_main_div {
        margin: 0.5rem 0;
        padding: 0 0.5rem;
    }

    .addTimecard-btn-class {
        font-size: 1rem;
        padding: 0.1rem 0.9rem;

        border-radius: 0.3rem;

    }

    .tablemobileView {
        width: 100%;
        overflow-x: scroll;
    }

    .timeCard-heading,

    .inputGroup,
    .timeCardTextarea,
    .timeCardTimeinput,
    .trashcard {
        min-width: 150px;

    }

    .timeCard-heading div,
    .timeCard div {
        text-align: left;
    }

    .nrmTimecardButtonSubmit {
        text-align: center;
    }

}

.assignToLogoStyleNew {
    cursor: pointer;
}

.assignToLogoStyleNew:hover+.messageForTimeCard {
    display: block;
}

.messageForTimeCard {
    display: none;
    position: absolute;
    bottom: 0%;
    width: 15rem;
    right: 100%;
    background-color: #44546f;
    color: white;
    /* text-align: center; */
    border-radius: 5px;
    padding: 0.7rem;
    font-size: 0.65rem;
    z-index: 1;
    /* white-space: nowrap; */
}

.outerDiv-For-TimeCard {
    border-radius: 0.5rem;
    /* border: 1px solid red; */
}

.headerRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 0.2rem;
    font-size: 0.8rem;
    color: black;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    /* padding: 0.5rem; */
    border-radius: 0.5rem 0.5rem 0 0;

}

.outerDiv-For-TimeCard-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 0.1rem;
    font-size: 0.8rem;
    color: black;
    margin: 0.2rem 0;
    /* padding: 0.5rem; */
    /* border: 1px solid #ccc; */
    border-radius: 0.5rem;
}

.headerCell {
    padding: 0.5rem;
}

.headerRow .headerRowChild:nth-child(2) {
    border-left: 1px solid #ccc;
}

.headerRow .headerRowChild:nth-child(3) {
    border-left: 1px solid #ccc;
}

.headerRow .headerRowChild:nth-child(4) {
    border-left: 1px solid #ccc;
}

.headerRowChild {
    padding: 0.5rem;
    font-weight: bold;
}

.disabled {
    opacity: 0.5;
    /* Make the icon look disabled */
    cursor: not-allowed;
    /* Change the cursor to indicate it's disabled */
}

.anchorTagForTimeCard:hover {
    border: none;
    text-decoration: none;
}

.anchorTagForTimeCard {
    cursor: pointer;
    color: blue;
    margin-top: 1rem;
}

.anchorTagForTimeCard li {
    color: blue;
    border-bottom: 3px solid rgb(16, 17, 162);
    font-weight: bold;
}

.FlexClassCOntainer {
    display: flex;
    justify-content: space-between
}

.showCountOfTimecard {
    font-size: 0.9rem;
    color: black;
    gap: 1rem;
    display: flex;
}

.TimeCardHeading {
    text-align: center;
    font-size: 1.4rem;
    color: #1011a2;
    font-weight: bold;
}

.timeCardLabel {
    display: none;
}

.timeCard-show-div {
    border: 1px solid #1011a2;
}

.totalTimeCardFlexDiv{
    /* display: flex; */
 
    font-size: 1rem;
}
.totalTimeCardFlexDiv p{
    padding: 0.5rem 0;
    font-size: 0.9rem;
}

@media (max-width:980px) {
    .hideHeadingMobileView {
        display: none;
    }

    .totalTimeCardFlexDiv p{
     padding: 0.5rem 0;
    }

    .totalTimeCardFlexDiv{
        width: 40%;
        display: block;
    }

    .timeCardDate {
        /* width: calc(50% - 1rem); */
    }

    .showCountOfTimecard {
        width: 50%;
        justify-content: end;
    }

    .FlexClassCOntainer {
        gap: 1rem;
    }

    .dAHyOd {
        width: 100%;
    }

    .timeCardDate input {
        width: 100%;
    }

    .showCountOfTimecard {
        flex-wrap: wrap;
        gap: 0.3rem;
    }

    .showCountOfTimecard p {
        margin: 0;
    }

    .timeCard-show-div svg {
        height: 1.3rem;
    }

    .DateParaTimeCard {
        font-size: 0.75rem;
    }

    .timeCard-show-div {
        padding: 0.3rem 0.5rem;
    }

    .timeCardTimeinput {
        width: 100%;
    }

    .timeCard {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        border: 1px solid #ccc;
        margin-bottom: 0.2rem;
        padding: 0.5rem 1rem;
    }

    .timeCardLabel {
        display: block;
        color: black;
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }

    .trashcard {
        display: flex;
        justify-content: end;
    }

    .hrmtimedetailCard_div {
        padding: 0;

    }

    .timcard-hidden-div {
        padding: 0.5rem 0.2rem;
        border: 1px solid #ccc;
    }

    .headerRow {
        display: flex;
        font-size: 0.7rem;
    }

    .outerDiv-For-TimeCard-content {
        display: flex;
    }


    .timeCardprojectNameDiv {
        width: 27%;
    }

    .timeCardTaskDiv {
        width: 27%;
    }

    .timeCardWorkingHoursDiv {
        width: 15%;
    }

    .timeCardDiscription {
        width: 31%;
    }
   
}

.timCardWindowMainDiv{
    border: 1px solid #ccc;
    width: 90%;
    position: fixed;
    top: 54%;
    left: 50%;
    height :90%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 999999999;
}