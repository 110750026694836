  
  .heading {
    font-size: 1.5rem;
    /* text-align: center; */
    margin-top: 1.5rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .input {
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
  }
  
  /* .button {
    background: #333;
    color: #fff;
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: opacity 0.3s ease;
  } */
  
  .button:disabled {
    opacity: 0.8;
  }
  
  .footer {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .link {
    color: #007bff;
    cursor: pointer;
  }
  
  .error {
    color: red;
    margin-top: 1rem;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      /* max-width: 100%; */
      /* padding: 0.5rem; */
    }
  
    .form {
      gap: 0.5rem;
    }
  
    .input {
      padding: 0.5rem;
    }
  
    .button {
      padding: 0.5rem;
    }
  }
  