/* .accordion-item {
	margin-bottom: 1rem;
	padding: 5px;
	color: aliceblue;
	text-decoration: none;
	background-color: transparent;
	border: none;
}

.accordion-item:hover {
	background: linear-gradient(45deg, #aabafa, transparent);
}

.accodion_link .accodion_link .ion-md-remove {
	display: none;
}

.accordion-button {
	background: none;
	border: none;
	color: aliceblue;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	color: #f5f7ff;
	padding: 1rem 0;
	text-decoration: none;
}

.accodion_link:hover {
	text-decoration: none;
}

.accordion-header img {
	width: 6%;
}

.accordion-header i {
	margin-left: auto;
}
.ul-class{
	padding-left: 0!important;
}

@media only screen and (min-width:320px) and (max-width:775px) {
	.accordion-header img {
		display: none;
	}
}

.accordion-header i {
	position: absolute;
	right: 0;
	top: 45%;
}

.accordion-button::after {
	width: 0 !important;
}
.accordion-button:not(.collapsed) {
    color: whitesmoke !important;
    background:none !important;
    box-shadow: none !important;
}

.workAs-heading{
	margin: 0.5rem 0;
    color: white;
    font-size: 1.5rem
}
.workAs-para{
    color: white;
    padding: 1rem 3rem;
    font-size: 1rem;
} */


.accordion-item {
	margin-bottom: 1rem;
	padding: 5px;
	color: #1012a2;
	text-decoration: none;
	background-color: transparent;
	border: none;
}

.accordion-item:hover {
	background: linear-gradient(45deg, #aabafa, transparent);
}

.accodion_link .accodion_link .ion-md-remove {
	display: none;
}

.accordion-button {
	background: none;
	border: none;
	color: aliceblue;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	color: #f5f7ff;
	padding: 1rem 0;
	text-decoration: none;
}

.accodion_link:hover {
	text-decoration: none;
}

.accordion-header img {
	width: 6%;
}

.accordion-header i {
	margin-left: auto;
}
.ul-class{
	padding-left: 0!important;
}

@media only screen and (min-width:320px) and (max-width:775px) {
	.accordion-header img {
		display: none;
	}
}

.accordion-header i {
	position: absolute;
	right: 0;
	top: 45%;
}

.accordion-button::after {
	width: 0 !important;
}
.accordion-button:not(.collapsed) {
    color: whitesmoke !important;
    background:none !important;
    box-shadow: none !important;
}

.workAs-heading{
	margin: 0.5rem 0;
    color: #1012a2;
    font-size: 1.5rem
}
.workAs-para{
	line-height: 22px;
    color: black;
    padding: 1rem 3rem;
    font-size: 1.1rem;
}