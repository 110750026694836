.blog-page {

	padding-top: 9rem;
}

.blog-page-heading {
	margin-bottom: 2rem;
	color: #1012a2;
	font-weight: 700;
}

.blog-main-page-grid {
	color: black;
	padding-bottom: 2rem;
	display: grid;
	/* grid-template-columns: repeat(4, 1fr); */
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	grid-column-gap: 1rem;
	grid-row-gap: 2rem;
	/* margin-bottom: 2rem; */
	/* background-color: #e7e7e7; */
	padding: 0.5rem;
}

.blog-card-main-div {
	display: flex;
	flex-direction: column;
	/* max-width: 263px; */
	padding: 0 2rem;
	cursor: pointer;
}

.blog-image-div {
	margin-bottom: 1rem;

}

.blog-image-div img {
	max-height: 10rem;
	min-height: 10rem;
	width: 100%;
}

.blog-card-detail-div {
	border-left: 2px solid #0036db;
	padding: 0 1rem;
	margin-top: 1rem;
}

.blog-card-detail-div h1 {
	color: black;
	font-weight: 600;
	letter-spacing: .01em;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}

.blog-card-author-name {
	color: black;
	margin: 0;
	margin-bottom: 0.2rem;
	font-size: 1.25rem;
}

.blog-card-creating-date {
	color: black;
	font-size: 0.8rem;
	margin: 0;

}


.para-limit1 {
	--max-lines: 3;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
}

.para-limit {
	--max-lines: 2;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
}

.blog-anchortag:hover {
	color: none !important;
	text-decoration: none !important;
}

@media (max-width: 980px) {
	.blog-main-page-grid {
		padding-top: 1rem;
		color: black;
		padding-bottom: 2rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		grid-column-gap: 1rem;
		grid-row-gap: 2rem;

	}
}

@media (max-width: 580px) {
	.blog-page-heading {
		margin-bottom: 1rem;
		font-size: 1.9rem;
	}

	.blog-page {
		padding-top: 7rem;
	}

	.blog-main-page-grid {
		padding-top: 1rem;
		color: black;
		padding-bottom: 1rem;
		display: grid;
		grid-template-columns: repeat(1, 1fr);

		grid-column-gap: 1rem;
		grid-row-gap: 2rem;

	}

	.blog-card-detail-div h1 {
		color: #1012a2;
		font-weight: 600;
		letter-spacing: normal;
		font-size: 1.3rem;
		margin-bottom: 2.5rem;
	}
}



.blogCard {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	/* width: 300px; */
	border: 1px solid #f2f7f8;
}

.blogCard h2 {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 10px;
	color: black;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.blogCardPara {
	color:black;
	font-size: 1rem;
	line-height: 1.5;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin:  0 0 0.5rem;
}

.blogCard img {
	width: 100%;
	margin-bottom: 20px;
	max-height:50%;
}

.blogCard .dot {
	width: 5px;
	height: 5px;
	background-color: #ddd;
	border-radius: 50%;
	display: inline-block;
	margin: 0 5px;
}

.blogCardBottomDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.blogCard .dot.active {
	background-color: #333;
}

.blogCard button {
	background-color: transparent;
	color: black;
	padding: 10px 0;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}
.blogCardAutherDiv{
	display: flex;
	justify-content: space-between;
}
.blogCardAutherDiv p{
	color: black;
font-size: 0.9rem;
}