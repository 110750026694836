.aboutheadingbg {

    width: 100%;
    margin-top: 0;
    min-height: 10vh;
    padding: 15vh 0 0 0;
    /* background-image: url('../../../public/assets/BG/service-pages_bg.jpg'); */
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    justify-content: space-between;
}

.aboutUsHeading {
    width: 75%;
    padding-top: 4vh;
}

.aboutsection-div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 5rem;
}

.sarea {
    border-radius: 0;
}

@media (max-width: 980px) {
    .aboutUsHeading {
        width: 100%;
    }

    .aboutsection-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}


.gallary-main-div {
    color: black;
}

.gallery {
    padding: 3rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.gallery div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.gallery div img {
    width: 100%;
    object-fit: contain;

}

.aboutWecreateSection {
    padding: 0.5rem 0 1rem 0;

    font-weight: 700;

    font-size: 2rem;
}

.aboutSectionValeHeading {
    padding: 1rem 0 3rem 0;

    font-weight: 700;

    font-size: 2rem;
    color: black;
}

.aboutBtns {
    margin: 1rem 0;
    border: none;
    font-size: 1.1rem;
    padding: 1rem 3rem;
}


.roiSectionHeading {
    padding-top: 2rem;
    color: black;
    font-size: 2.3rem;
    letter-spacing: 0;
}

.aboutScetionMain-div {
    margin-top: 0;
    /* min-height: 100vh; */
    padding: 155px 0 2rem 0;
    background-color: white;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.aboutUsbannerServiceSetction-inner-div {
    background-color:
        #fcdc4b;
    justify-content: end;
    margin: 1rem 0 1rem 0;
}

.about-right-div {
    display: flex;
    justify-content: start;
    width: 100%;
}

.aboutUsbannerServiceSetction {
    width: 90%;
}

.aboutUsbannerServiceSetction-inner-div {
    padding-top: 0.5rem;
    display: flex;
    /* justify-self: end; */
    justify-content: space-between;
    flex-direction: column;
}

.aboutUsbannerServiceSetction-inner-div h1 {
    text-align: right;
    padding-right: 1rem;
    color: black;
    font-size: 2.5rem;
}

.aboutUsbannerServiceSetction-inner-div p {
    /* position: absolute; */
    /* background-color: #2d58ed; */
    color: white;
    width: 90%;
    align-self: end;
    padding: 0.5rem 0;
    margin: 0;
    padding-right: 1rem;
    font-size: 1.2rem;
    /* font-weight: 400; */
    text-align: end;
}

.aboutUsbannerServiceSetction-inner-div:nth-child(1) p {
    background-color: #2d58ed;
}

.aboutUsbannerServiceSetction-inner-div:nth-child(2) p {
    background-color: #063ddb;
}

.aboutUsbannerServiceSetction-inner-div:nth-child(3) p {
    background-color: #172ba3;
}

@media (max-width: 580px) {
    .gallery {
        /* height: 60vh; */
        /* overflow: hidden;
        overflow-y: scroll; */
        padding: 3rem 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }

    .gallery div {
        display: flex;
        flex-direction: column;
        gap: 1rem;

    }

    .aboutScetionMain-div {
        padding: 9rem 0 2rem 0px;

    }

    .roiSectionHeading {
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    .aboutScetionMain-div-main-heading {
        font-size: 2rem;
    }
    .aboutBtns {
        width: 100%;
    }
    .banner-row-div{
        padding: 0 0.5rem;
    }
    
}


.triangle-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
}

.triangle {
    width: 0;
    height: 0;
    position: relative;
    overflow: hidden;
    min-width: 115%;
    min-height: 300px;
}

.triangle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 8%, 0% 100%, 100% 100%);
}

.triangle p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    text-align: center;
    width: 80%;
    color: white;
    font-size: 1rem;
    margin: 0;
}

.triangle:nth-child(1)::before {
    background-color: #2d58ed;
    left: 5%;
}

.triangle:nth-child(2)::before {
    background-color: #063ddb;
}

.triangle:nth-child(3)::before {
    background-color: #172ba3;
}

.triangle-container .triangle:nth-child(2) {
    left: -15%;
}

.triangle-container .triangle:nth-child(3) {
    left: -30%;
}

.aboutUSbanner-right-div {
    display: flex;
    align-items: end;
    padding-bottom: 0rem 0 1rem 1rem;
}