body {
	margin: 0;
	box-sizing: border-box;
	/* font-family: 'Roboto', sans-serif !important; */
	/* font-family: 'Open Sans', sans-serif !important; */
	font-family: 'Lato', sans-serif !important;
    /* font-family:  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}



.btns {
	margin: 2.5rem 0;
	padding: 1.3rem 4rem;
	background: #fcd84a;
	color: black;
	border: none;
	border-radius: 0.2rem;
	font-weight: 500;
}

.btns a {
	text-decoration: none;
	color: black;
}

.btns:hover {
	color: whitesmoke;
}


.heading {
	margin-top: 1rem;
	color: #101240;
	font-weight: 700;
	/* letter-spacing: 5px; */
	font-size: 2rem;
	margin-bottom: 1rem;
}

.title_sub {
	letter-spacing: 2px;
text-align: left;
	color: #1012a2;
	margin-bottom: 1rem;
}

.section-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 10px;
	color: #212121;
}

.srv {
	/* text-align: center; */
	padding: 25px 10px;
	border-radius: 5px;
	font-size: 14px;
	cursor: pointer;
	background: transparent;
	transition: transorm 0.5s;
}

.srv i {
	font-size: 35px;
	margin-bottom: 10px;
	color: #1012a2;
}

.srv button {
	font-size: 1rem;
	color: #1012a2;
}

.srv h2 {
	text-transform: uppercase !important;
	color: black;
	font-size: 1.3rem;
	margin-bottom: 8px;
}

.service-dis-section {
	font-size: 1rem;
	line-height: 22px;
	margin-bottom: 1rem;

}


.srv.srvbtn {
	width: auto;
	padding: 0 1.5rem;
	margin-top: -1px;
}

.srv.srvbtn a {
	font-weight: 500;
	color: #172ba3;
	text-decoration: none;
}



.srvbtn {
	padding: 0.5rem 1.5rem;
	width: 120px;
	height: 40px;
	border-radius: 0;
	border: 2px solid #172ba3;
	justify-content: center;
	align-items: center;
	color: #576975;
	margin-top: 10px;
	text-decoration: none;
}

.factChild {
	padding: 50px !important;
	background-color: #f4f4f4;
	border-radius: 10px;

	display: flex;
	-webkit-box-align: center;
	align-items: center;
	box-shadow: 5px 5px 10px rgba(82, 81, 81, 0.6);

}

.bannerServiceSetction-inner-div h1 {
	width: 30%;
	margin: 0;
	margin-bottom: 0rem;
	font-size: 3.2rem;
	font-weight: bolder;
	color: #1012a2;
}

.factContent {
	position: absolute;
	left: 25px;
	margin: 1rem 0 0 0rem;
}

.implemantationDonHeading {
	color: rgb(0, 54, 219) !important;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}


.banner {
	margin-top: 0;
	min-height: 75vh;
	padding: 155px 0 0px 0;
	background-color: white;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
}

.topHeaderHeading {
	/* color: #1012a2; */
	color: black;
	/* font-size: 3rem; */
	font-weight: 700;
	letter-spacing: 0.8px;
	/* line-height: 3rem; */
	/* margin-bottom: 20px; */
	display: block;
}

.banner p {
	color: black;
	font-size: 16px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	width: 96%;
}

.bannerServiceSetction-inner-div p {
	width: 100%;
}

.banner img {
	animation: updown 3s linear infinite;
}

.banner-inner-div {
	width: 100%;
	padding: 0 10%;
	background-color: white;
}

.banner-left-div {
	padding-top: 1%;
}

.banner-right-div {
	background-color: #f2f7f8;
	padding: 155px 0 0px 0;
}

.banner-right-innerdiv {

	padding: 1rem 2rem;
}

@keyframes updown {
	0% {
		transform: translateY(-20px);
	}

	50% {
		transform: translateY(20px);
	}

	100% {
		transform: translateY(-20px);
	}
}

.txt-rotate>.wrap {
	border-right: 0.08em solid #666;
}


.banner img {
	width: 120%;
	margin-top: -5rem;
}


@media only screen and (min-width:1700px) {
	.banner-right-div {
		/* padding-top: 8%; */
		padding: 155px 0 0px 0;
	}

	

	.bannerServiceSetction-inner-div h1 {
		width: 30%;
		margin: 0;
		margin-bottom: 0rem;
		font-size: 4rem;
		font-weight: bolder;
		color: #1012a2;
	}

	.topHeaderHeading {
		color: whitesmoke;
		font-size: 4.5rem;
		font-weight: 700;
		letter-spacing: 0.8px;
		/* line-height: 1; */
		margin-bottom: 20px;
		display: block;
	}

	.implemantationDonHeading {
		color: rgb(0, 54, 219) !important;
		font-size: 2.6rem;
		margin-bottom: 1.5rem;
	}

	.banner p {
		color: #B8B8B8;
		font-size: 1.3rem;
		letter-spacing: 0.8px;
		line-height: 1.5em;
		width: 96%;
	}

	.bannerServiceSetction-inner-div p {
		width: 100%;
	}

	.btns {
		margin-right: 2rem;
	}

	.spanClassForImplemantation {
		/* color: rgb(0, 54, 219); */
		color: black;
		font-size: 2rem;
		/* font-weight: 500; */
		display: flex;
		align-items: center;
	}
}

.btns {
	margin-right: 2rem;
}

@media only screen and (max-width:768px) {
	.banner img {
		width: 100%;

	}
}


@media(max-width:1200px) {
	.banner-left-div {
		padding-top: 1rem;
	}

	.banner-inner-div {
		width: 100%;
		height: 85vh;
		padding: 0 1rem;
	}

	.banner-right-innerdiv {
		padding: 1rem 1rem;
	}

	.fvlJhx {
		height: 15vh;

	}

	.topHeaderHeading {
		color: whitesmoke;
		font-size: 3rem;
		font-weight: 700;
		/* letter-spacing: 0.8px;
		line-height: 2rem; */
		/* margin-bottom: 20px; */
		display: block;
	}

	.banner p {
		color: #cceafe;
		font-size: 1rem;
	}

	.banner-right-div {
		margin-bottom: 2rem;
		/* display: flex;
		align-items: center;
		justify-content: center; */
	}

}

@media only screen and (max-width:1050px) {
	.banner img {
		width: 100%;
	}

	.banner {
		margin-top: 0;
		min-height: auto;
		height: auto;
		padding: 130px 0 0px 0;
		/* background-image: url('./assets/main_bg_02.png'); */
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.btns {
		margin-right: 0.5rem;
	}
}



@media only screen and (max-width:750px) {
	.banner-right-div {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 2rem 0 2rem 0;
	}

	.topHeaderHeading {
		color: whitesmoke;
		font-size: 1.6rem;
		font-weight: 700;
		/* letter-spacing: 0.8px;
		line-height: 1; */
		/* margin-bottom: 20px; */
		display: block;
	}

	.btns {
		margin: 1rem 0;
	}

}



@media only screen and (max-width:580px) {
	.banner {
		/* min-height: 75vh; */

		padding: 8rem 0 0 0px;
	}

	.topHeaderHeading {
		color: whitesmoke;
		font-size: 2.3rem;
		font-weight: 700;
		/* letter-spacing: 0.8px;
		line-height: 1; */
		line-height: normal;
		letter-spacing: normal;
		/* margin-bottom: 1rem; */
		display: block;
	}

	.btns {
		font-size: 1.2rem;
	}

	.banner-left-div {
		padding-top: 0rem;
	}

	.banner p {
		color: #cceafe;
		font-size: 0.8rem;
		line-height: normal;
		letter-spacing: normal;
	}

	.partner-bold {
		color: red;
	}

	.btns {
		width: 100%;
		margin: 0 0;
	}

	.banner img {
		width: 80%;
		margin-top: -1.5rem;
	}

	.btn-home-container {
		display: flex;
		/* align-items: center; */
		justify-content: center;
	}

	.section-row {
		grid-gap: 0px;
	}

	.banner-right-div {
		display: block;
	}

}


.optionContainer li {
	color: #1012a2 !important;
}

.HWW_Bg {
	/* background-image: url(../public/assets/BG/hww_01bgd.png); */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 1% 0 5%;

}


.contact_Bg {
	background-image: url(../public/assets/BG/contact_bg02.jpg);

	padding: 120px 0px;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

}

.services_bg {
	/* background-image: url(../public/assets/BG/services_bg.jpg); */
	background-color: #f2f7f8;
	height: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 1% 0 5%;
	background-attachment: fixed;
}

.slideUp {
	animation-name: slideUp;
	animation-name: slideUp;
	animation-duration: 1s;
	animation-duration: 1s;
	visibility: visible;
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(70%);
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}


.services-submenu {
	width: 10rem;
	position: absolute;
	top: 50px;
	list-style: none;
	text-align: center;
}

.services-submenu li {
	background: rgb(240, 255, 255);
	cursor: pointer;
}

/* .services-submenu li a:hover {
	background: rgb(0, 175, 175);
} */

.services-submenu.clicked {
	display: none;
}

.submenu-item {
	display: block;
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: rgb(28, 32, 135);
	padding: 16px;
}


.reveal {
	position: relative;
	transform: translateY(55px);
	opacity: 0;
	transition: 1s all ease;
}

.reveal:active {
	transform: translateY(0);
	opacity: 1;
}



/* LeftSection */


.bannerServiceSetction {
	/* width: 75%; */
	/* border-left: 1px solid #ccc; */
	position: relative;
	/* border-left: 2px solid #fcd84a; */
}

.bannerServiceSetction-inner-div {
	margin: 1.5rem 0;
	display: flex;

	gap: 0.5rem;
}

.bannerServiceSetction-inner-div h1 {
	width: 30%;
	margin: 0;
	margin-bottom: 0rem;
	/* font-size: 3.2rem; */
	font-weight: bolder;
	color: #1012a2;
}

.bannerServiceSetction-inner-div p {
	margin: 0rem 0;
	color: black;
	font-size: 0.9rem;
}

.bannerServiceSetction-inner-div:nth-child(1) {

	/* border-left: 5px solid rgb(5, 61, 219); */
	margin-top: 0;
}

/* .bannerServiceSetction-inner-div:nth-child(2)::after{
    
    background-color:#1012a2 ;
} */

.bannerServiceSetction-inner-div:nth-child(3) {

	margin-bottom: 0;
}




.rdw-dropdownoption-default {
	color: black !important;
}

.rdw-editor-toolbar {
	z-index: 999;
	top: 4rem;
	position: sticky !important;
	visibility: visible;
}

.spanClassForImplemantation {
	/* color: rgb(0, 54, 219); */
	color: black;
	/* font-size: 1.3rem; */
	/* font-weight: 500; */
	display: flex;
	align-items: center;
}

.implemantationDonHeading {
	color: rgb(0, 54, 219) !important;
	/* font-size: 1.5rem; */
	margin-bottom: 1.5rem;
}

@media(max-width:980px) {
	.bannerServiceSetction-inner-div h1 {
		width: 40%;
		/* font-size: 1.75rem; */

	}

	.spanClassForImplemantation {
		/* color: rgb(0, 54, 219); */
		color: rgb(0, 54, 219);
		font-size: 1.3rem;
		font-weight: 500;
	}

	.bannerServiceSetction-inner-div p {
		/* margin: 0.rem 0; */
		color: black;
		/* font-size: 1.2rem; */
		font-weight: 500;
	}

	.margin-bottom {
		margin-bottom: 1rem;
	}

	.fvlJhx {
		height: 16vh;

	}
}

@media(max-width:680px) {
	.bannerServiceSetction-inner-div {
		margin: 1rem 0;
		display: flex;
		gap: 0.5rem;
	}

	.banner-right-div {

		padding-top: 1rem;
	}

	.banner {
		min-height: auto;
	}

	.implemantationDonHeading {
		margin-bottom: 1rem;
	}

	

	.spanClassForImplemantation {
		/* color: rgb(0, 54, 219); */
		color: rgb(0, 54, 219);
		font-size: 1.3rem;
		font-weight: 500;
	}

	.bannerServiceSetction-inner-div p {
		/* margin: 0.rem 0; */
		color: black;
		/* font-size: 1.2rem; */
		font-weight: 500;
	}

	.btns {

		padding: 1.3rem;

	}

	.bannerServiceSetction-inner-div h1 {
		width: 25%;
		margin: 0;
		margin-bottom: 0rem;
		font-size: 1.8rem;
		font-weight: bolder;
		color: #1012a2;
	}
}

.banner-main-TwoColumn-div {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

@media(max-width:980px) {
	.bannerServiceSetction-inner-div h1 {
		width: 30%;
		margin: 0;
		margin-bottom: 0rem;
		font-size: 2.2rem;
		font-weight: bolder;
		color: #1012a2;
	}

}


.newBanner-inner-div {
	width: 100%;
	padding: 0 10%;
	background-color: white;
}

.banner-row-div {
	display: grid;
	grid-template-columns: calc(55% - 1rem) 45%;

}

@media(max-width:1200px) {
	.newBanner-inner-div {
		width: 100%;
		height: 85vh;
		padding: 0 1rem;

	}
}
@media(max-width:1200px) {
	.newBanner-inner-div {
		width: 100%;
		height: 85vh;
		padding: 0 1rem;

	}
}
@media(max-width:980px) {
	.banner-row-div {
		display: grid;
		grid-template-columns: repeat(1,1fr)
	
	}
	.btns {
		margin: 1rem 0 3rem 0;
		padding: 1.3rem 4rem;
		background: #fcd84a;
		color: black;
		border: none;
		border-radius: 0.2rem;
		font-weight: 500;
	}
	.banner-right-div {
		background-color: #f2f7f8;
		padding: 1rem 0 0px 0;
	}

}

.draggable {
	top: auto !important;
	left: auto !important;
  }