.UseFullFormLinks-mainClass {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


a.UseFullFormLinks-form-row {
    cursor: pointer;
    text-decoration: none !important;
    color: inherit;

    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: box-shadow 0.3s ease;
}

a.UseFullFormLinks-form-row:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}


.UseFullFormLinks-form-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-decoration: none !important;
    border: none;
    margin: 0;
}


.UseFullFormLinks-icon-buttons {
    display: flex;
    gap: 16px;
}


.UseFullFormLinks-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px;
    transition: transform 0.2s ease;
}

.UseFullFormLinks-icon-button:hover {
    transform: scale(1.1);
}


.UseFullFormLinks-icon {
    width: 2rem;
    height: 2rem;
    stroke: currentColor;
}


.UseFullFormLinks-icon-button {
    color: #2563eb;
}
@media(max-width:780px){
    .UseFullFormLinks-form-title{
        font-size: 1rem;
    }
    .UseFullFormLinks-icon {
        width: 1.3rem;
        height: 1.3rem;
        stroke: currentColor;
    }
}