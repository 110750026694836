.hrmNavLeftDiv {
    display: flex;
    justify-content: end;
}

/* .hrmNavLeftDiv div {
    display: flex;
} */

.hrmNavLeftDiv div li {
    margin: 0 2rem;
}

.hrmAdminPanelNavbar {
    display: flex;
    z-index: 100;
    width: 100%;
    position: sticky;
    background-color: white;
    top: 0;
    justify-content: space-between;
    padding: 0.5rem 1.1rem;
    /* height: 10vh; */
    box-shadow:1px 0px 3px #1011a2;
    /* box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px; */
}

.headingOfhrm {
    margin: 0rem;
}

.hrmAdminPanelNavbar h1 {
    font-size: 1.5rem;
    font-weight: 800;
    color: #1011a2;
    letter-spacing: 3px;
    padding: 0px 3px;
    letter-spacing: 2px;

}

.profileButton {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: rgb(217, 120, 138);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-div {
    position: relative;
}

.dropDownForProfileDiv {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    z-index: 9999999;
    padding:0.5rem;
    width: 15rem;
}
.dropDownForProfileDiv p{
    font-size: 0.9rem;
    margin: 0;
}
.drop-profile-div{
    padding-bottom: 0.3rem;
}
.drop-profile-div button{
    font-size: 1rem;
}
.drop-hrmBtn{
    border: none;
    font-size: 0.9rem;
}
.drop-profile-div:hover{
   background-color: #f5f5f5;
}
.dropDownForProfileDiv a:hover {
    text-decoration: none;
}
.dropDownForProfileDiv p,.dropDownForProfileDiv a  {
    color: #1011a2;
}
.navBarSearch{
    height: 30px;
    width: 100%;
    padding: 0px var(--ds-space-150, 12px) 0px 30px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    border: var(--ds-border-width, 1px) solid var(--ds-border-input, #7A869A);
    box-shadow: none;
    background-color: var(--ds-background-input, #FFFFFF);
    color: var(--ds-text-subtlest, #6B778C);
}
/* .serachDropDown{
    position: relative;
    width: 780px;
    box-shadow: var(--ds-shadow-overlay, 0 4px 8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));
    border-radius: 3px;
    background-color: var(--ds-surface-overlay, #fff);
    overflow: hidden;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
} */
 .headingOfhrm:hover{
    color: #1011a2;
    text-decoration: none;
 }
 .headingOfhrm{
   color: #1011a2;
 }
 .centerClass{
    display: flex;
    align-items: center;
    justify-content: center;
 }
 @media(max-width:980px){
    .hrmAdminPanelNavbar{
        padding: 0.5rem;
        gap: 1rem;
            }
 }