.post {
    margin: 1rem 0;
    border: 1px solid #3b0062;
    border-radius: 5px;
    padding: 0.5rem;
  }
  
  .post__meta {
    font-size: 1rem;
    color: #707070;
    margin: 0;
  }
  
  .post__title {
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #3b0062;
  }
  .post__header p{
    margin: 0.5rem 0;
  }
  .post__image {
    height: 15rem;
    width: 100%;
  }
  
  .post__actions {
    text-align: right;
  }
  
  @media (min-width: 768px) {
    .post {
      padding: 1rem;
      width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .contactUs-header{
    display: flex;
    justify-content: space-between;
  }
  .buttonCreatePost{

    font: inherit;
    border: 1px solid #3b0062;
    color: white;
    background-color: #3b0062;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
  }