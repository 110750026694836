.editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 10px;
  }
  .mention-dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    width: 5rem;
    overflow-y: auto;
    z-index: 1000; 
    background-color: white !important;
  }
  
  .suggestion li{
  list-style: none !important;
  }
  
  .mention-item {
    padding: 8px 12px;
    cursor: pointer;
    list-style: none !important;
  }
  
  .mention-item:hover {
    background-color: #f0f0f0;
  }
  
  .mention-item.active {
    background-color: #bad9fa;
    color: #fff;
  }
  
  
  .tippy-arrow{
    display: none !important;
  }
  .tippy-box{
    padding: 0 1rem;
    background-color: white!important;
    color: black;
    font-size: 1.2rem !important;
  }
  .suggestion-item:hover{
    background-color: rgba(9, 30, 66, .08);
  }

  .editor-button {
    padding: 3px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    cursor: pointer;
  }
  .editor-button svg{
    height: 1rem;
  }
  .editor-button.active {
    background: #e0e0e0;
  }
  
  
  .suggestion {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    width: max-content; 
    overflow-y: auto;
    z-index: 1000;
  }
  
  .suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestion-item:hover,
  .suggestion-item.active {
    background-color: rgba(9, 30, 66, .08);
  }
  
  .mention {
    display: inline;
    border: 1px solid transparent;
    background: rgb(16 17 162);
    color: white;
    border-radius: 20px;
    cursor: pointer;
    padding: 0px 0.3em 2px 0.23em;
    line-height: 1.714;
    font-size: 0.9em;
    font-weight: normal;
    word-break: break-word;
  }
  
  
  .ProseMirror{
    padding: 0.5rem;
    border: 1px solid #ccc;
  }
  .textEditorDiv{
    color: black;
    padding: 0.5rem;
    border: 1px solid #ccc;
  }
  .ProseMirror-focused{
  
    border: 1px solid #ccc !important;
  }
  .editor-content{
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;

  }

  .color-picker-container {
    position: relative;
    display: inline-block;
    display: flex;
}

.color-picker {
    width: 2rem;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.color-picker__swatches {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; 
    width: 10rem;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1;
}

.color-picker__swatch {
    width: 1rem;
    height: 1rem;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
}

.color-picker__swatch:hover {
    border: 1px solid #000;
}

.color-picker__selected {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid black;
}
.colorPickerDroipDown{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.3rem;
  border: 1px solid #ccc;
}
.colorPickerDroipDown svg{
height: 1rem;
}
.suggestion-item-char{
  width: 2rem;
    height: 2rem;
    background-color: #ccc;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.suggestion-item-container{
  display: flex;
  gap: 0.5rem;
}

.suggestion-item-name{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hyperLinkDropDown{
  display: flex;
  flex-wrap: wrap;
  width: 10rem;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding:1rem 0.5rem;
  z-index: 1;
}
.hyperLinkDropDown input{
  width: 100%;
  margin-bottom: 1rem;
}
.hyperLinkDropDown button{
border: none;
text-align: center;
width: 100%;
background-color: #ccc;
}

.shadedLoader-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.shadedLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006f; 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
  pointer-events: none;
}

.shadedLoader::after {
  content: '';
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid rgb(16, 17, 162);
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  z-index: 10;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tiptap img{
  width: 100% !important;
}