.auth-form {
    width: 90%;
    margin: auto;
    padding: 1rem;
    border: 1px solid #3b0062;
    border-radius: 5px;
   
  }
  
  @media (min-width: 768px) {
    .auth-form {
      width: 50%;
    }
  }
  