@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
	--header-height: 3.5rem;
	--first-color: hsl(220, 68%, 54%);
	--first-color-lighten: hsl(220, 68%, 97%);
	--title-color: hsl(220, 48%, 28%);
	--text-color: hsl(220, 12%, 45%);
	--body-color: hsl(220, 100%, 99%);
	--body-font: "Poppins", sans-serif;
	--normal-font-size: .7rem;
	--small-font-size: .6rem;
	--smaller-font-size: .5rem;
	--font-medium: 500;
	--font-semi-bold: 600;
	--z-tooltip: 10;
	--z-fixed: 100;
}

@media screen and (min-width: 1024px) {

	:root {

		--normal-font-size: 1rem;
		--small-font-size: .875rem;
		--smaller-font-size: .813rem;
	}

}


* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

body {
	font-family: var(--body-font);
	background-color: var(--body-color);
	color: var(--text-color);
	scroll-behavior: smooth;
}

.header2 ul {
	list-style: none;
}

.header2 a {
	text-decoration: none;
}

.header {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100vw;
	box-shadow: 0 2px 8px hsla(220, 68%, 12%, 0.1);
	background-color: white;
	z-index: var(--z-fixed);
}

.header2 {
	width: 100vw;
	background-color: white;
}


.nav__data {
	height: 100%;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav__logo {
	display: inline-flex;
	align-items: center;
	column-gap: 0.25rem;
	color: var(--title-color);
	font-weight: var(--font-semi-bold);
	transition: color 0.3s;
}

.nav__logo i {
	font-size: 1.25rem;
}

.nav__logo:hover {
	color: var(--first-color);
}

.nav__toggle {
	position: relative;
	width: 32px;
	height: 32px;
}

.nav__toggle-menu,
.nav__toggle-close {
	font-size: 1.8rem;
	width: auto;
	color: blue;
	position: absolute;
	display: grid;
	place-items: center;
	inset: 0;
	cursor: pointer;
	transition: opacity 0.1s, transform 0.4s;
	margin: -1rem;
	right: -2rem
}

.nav__toggle-close {
	opacity: 1;
}

@media screen and (max-width: 1118px) {

	.nav__menu {
		background-color: var(--body-color);
		position: absolute;
		left: 0;
		top: 2.5rem;
		width: 100%;
		height: calc(100vh);
		overflow: auto;
		padding: 1rem 0;
		pointer-events: none;
		opacity: 0;
		transition: top 0.4s, opacity 0.3s;
	}

	.nav__menu::-webkit-scrollbar {
		width: 0.5rem;
	}

	.nav__menu::-webkit-scrollbar-thumb {
		background-color: hsl(220, 12%, 70%);
	}

	.height_dropdown {
		height: 668px;
	}

}

.nav__link {
	color: inherit;
	font-weight: var(--font-semi-bold);
	padding: 1.25rem 1.5rem;
	height: 5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: background-color 0.3s;
}

.nav__link:hover {
	background-color: var(--first-color-lighten);
}

.show-menu {
	opacity: 1;
	top: 0rem;
	
	padding: 1rem 0;
	pointer-events: initial;
}
.nav__menu {
	padding: 0;
}
.show-icon .nav__toggle-menu {
	opacity: 0;
	transform: rotate(90deg);
}

.show-icon .nav__toggle-close {
	opacity: 1;
	transform: rotate(90deg);
}

.dropdown__button {
	cursor: pointer;
}

.dropdown__arrow {
	font-size: 1.5rem;
	font-weight: initial;
	transition: transform 0.4s;
}

.dropdown__content,
.dropdown__group,
.dropdown__list {
	display: grid;
}

.dropdown__container {
	background-color: var(--first-color-lighten);
	height: 0;
	overflow: hidden;
	transition: height 0.4s;
}

.dropdown__content {
	row-gap: 1.75rem;
}

.dropdown__group {
	padding-left: 1.5rem;
	row-gap: 0.5rem;
	display: flex;
}

.dropdown__group:first-child {
	margin-top: 1.25rem;
}

.dropdown__group:last-child {
	margin-bottom: 1.25rem;
}

.dropdown__icon {
	display: grid;
	place-items: center;
}

.dropdown__icon i {
	font-size: 1.25rem;
	color: var(--first-color);
	margin-right: 1rem;
}

.dropdown__title {

	font-size: 1rem;
	font-weight: var(--font-semi-bold);
	color: var(--title-color);
	display: grid;
	place-items: center;
}

.dropdown__list {
	row-gap: 0.25rem;
}

.dropdown__link {
	font-size: var(--smaller-font-size);
	font-weight: var(--font-medium);
	color: var(--text-color);
	transition: color 0.3s;
}

.dropdown__link:hover {
	color: var(--title-color);
}

.show-dropdown .dropdown__arrow {
	transform: rotate(180deg);
}

.logo {
	margin: 0.1rem 0.1rem 0.1rem 1rem;
	height: auto;
	width: 11rem;
}

.nav__link {
	color: #253c6a;
}

.mobile-view-menu{
display: flex;
justify-content: space-between;

}
.mobile-view-toggle-cross{
	display: flex;
	align-items: center;
	justify-content: center;
}
.mobile-view-toggle-cross i{
	font-size: 2rem;
}
@media screen and (max-width: 300px) {
	.dropdown__group {
		padding-left: 1.5rem;
	}

	.height_dropdown {
		height: 668px;
	}

}

@media screen and (min-width: 1118px) {
	.mobile-view-menu{
		display: none;
	}
	.header {
		position: fixed !important;
		height: 4.5rem;
	}

	.header2 {
		background-color: white;
		height: 6rem;
		display: grid;
		place-items: center;
	}

	.nav {
		height: calc(var(--header-height) + 2rem);
		display: flex;
		justify-content: space-between;
	}

	.nav__toggle {
		display: none;
	}

	.nav__list {
		display: flex;
		column-gap: 3rem;
	}

	.nav__data {
		width: 0;
	}

	.nav__textcolor {
		padding: 0;
		color: blue;
	}

	.logo_b {
		left: 11rem;
		position: absolute;
		height: 6rem;
		top: 7px;
	}
	.logo_b2 {
		left: 15rem;
		position: absolute;
		height: 4rem;
		top: 13px;
	}

	.logo {
		left: 8rem;
		position: absolute;
		height: 4.2rem;
		width: auto;
		top: 1rem;
	}
	.logo2 {
		left: 22rem;
		position: absolute;
		height: 6rem;
		width: auto;
		top: 1rem;
	}
	.nav li {
		display: flex;
		/* font-size: 1.2rem; */
	}

	.nav__link {
		padding: 0;
		color: blue;
	}

	.nav__link:hover {
		background-color: initial;
	}

	.dropdown__button {
		column-gap: 0.25rem;
		pointer-events: none;
	}

	.dropdown__container,
	.dropdown__container2 {
		height: max-content;
		position: absolute;
		left: 0;
		right: 0;
		top: 5rem;
		background-color: var(--body-color);
		box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);
		pointer-events: none;
		opacity: 0;
		transition: top 0.2s, opacity 0.3s;
		padding: 0.2rem;
		width: 60% !important;
		left: 30%;
		right: 10%;
		border-radius: 5px;
	}

	.dropdown__content {
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr);
		column-gap: 1rem;
		margin-inline: auto;
		row-gap: 1.50rem;
		padding: 1rem;
	}

	.dropdown__group {
		align-content: baseline;
		display: flex;
	}

	.dropdown__group:first-child,
	.dropdown__group:last-child {
		margin: 0;
	}

	.dropdown__icon {
		display: grid;
		place-items: center;
	}

	.dropdown__icon i {
		font-size: 1.5rem;
		margin-right: 1rem;
	}

	.dropdown__title {
		display: grid;
		place-items: center;
		font-size: 1rem;
	}

	.dropdown__link {
		font-size: var(--small-font-size);
	}

	.dropdown__link:hover {
		color: var(--first-color);
	}

	.dropdown__item {
		cursor: pointer;
	}

	.dropdown__item:hover .dropdown__arrow {
		transform: rotate(180deg);
	}

	.dropdown__item:hover>.dropdown__container {
		top: 4.6rem;
		opacity: 1;
		pointer-events: initial;
		cursor: initial;
	}

	.dropdown__item:hover>.dropdown__container2 {
		top: 4.6rem;
		opacity: 1;
		pointer-events: initial;
		cursor: initial;
		width: 60%;
		left: 30%;
		right: 10%;
		border-radius: 5px;
	}

	.nav__text_color {
		color: white;
	}

	.nav__textcolor {
		color: white;
	}

}

@media screen and (min-width: 1152px) {
	.container {
		margin-inline: auto;
	}

}

.height_dropdown {
	height: auto;
}

.logo_b {
	height: 4rem;
	margin-left: 1rem;
}
.logo_b2 {
	height: 3rem;
	margin-left: 0rem;
}
.logo2 {
	margin: 0.1rem 0.5rem 0.1rem 0rem;
	height: auto;
	width: 7rem;
}
.first_color {
	color: white;
}

.secont_color {
	color: blue;
}
.flexClass{
	display: flex;
	align-items: center;
}

.nav li {
	font-size: 1.2rem;
}