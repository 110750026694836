* {
	box-sizing: border-box;
}

.headingbg {

	width: 100%;
	margin-top: 0;
	min-height: 75vh;
	padding: 20vh 0 0 0;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;


	justify-content: space-between;

}

.sevcontainer {
	height: 100%;
	display: grid;
	padding: 0 5%;
	grid-template-columns: 60% 40%;
}

.content-heading-div {

	width: 100%;
	/* display: flex;
	
	justify-content: center;
	align-items: center; */

}

.heading_details {

	display: inline-block;
	overflow-y: hidden;
	display: inline-block;
	align-items: center;
	justify-content: center;
}

.heading_details h3,
.sevcontainer li {
	color: white;
	text-align: left;
}

.heading_img_div {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	flex-direction: column-reverse;
	margin-bottom: 1rem;
}

.heading_img_div img {

	min-width: 45%;
	max-width: 85%;
}

.moving_img_div {
	height: 25vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.moving_img_div img {

	display: grid;
	place-items: center;
	width: 80%;
}

@media only screen and (max-width:920px) {

	.sevcontainer {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(auto, 1fr);
	}

	.headingbg {
		height: fit-content;

	}

	.content-heading-div {
		padding: 0 2rem;
	}

	.content-heading-div {
		padding-bottom: 6%;
	}

	.heading_img_div {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0rem;
	}

	.heading_img_div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 0;
	}

	.moving_img_div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.moving_img_div img {
		display: grid;
		place-items: center;
		width: 100%;
	}

	.heading_points {

		margin-bottom: 0.5rem;
		font-size: 1rem;
		list-style: none;
	}

	.heading_img_div img {
		max-width: 100%;
		width: auto;
		object-fit: fill;

	}

}

@media only screen and (max-width:675px) {

	.moving_img_div {
		display: flex;
		align-items: center;
		justify-content: center;
		animation-duration: 20s;
		animation-timing-function: linear;
		animation-iteration-count: infinite
	}

	.moving_img_div img {
		display: grid;
		place-items: center;
	}

}

@media only screen and (max-width:750px) {
	.heading_img_div {
		margin-bottom: 0rem;
	}

	.moving_img_div {
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.moving_img_div img {

		width: 100%;
	}


}

.heading_details h3 {
	font-weight: 600;
	margin-bottom: 2rem;
	font-size: 2.5rem;
	color: #009cdb;
}

.heading_details h3 span {
	display: block;
	color: #1012a2;
	font-size: 3rem;
	margin-top: 1rem;
}

.service_heading {
	font-weight: 600;
	margin-bottom: 0.75rem;
	font-size: 1.5rem;
}

.heading_details li b {
	color: #ff00dd;
	font-size: 1.2rem;

	margin: 0 0.1rem;

}

.heading_details li {
	line-height: 1.8rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
	font-size: 1.1rem;
	list-style: none;
	color: black;
}

@media only screen and (max-width:920px) {
	.heading_points {

		margin-bottom: 0.5rem;
		font-size: 1.1rem;
		list-style: none;
	}

	.headingbg {
		margin-top: 0;
		height: 75vh;
		padding: 15vh 0 0 0;
	}



	.heading_img_div img {
		width: 100%;
		object-fit: fill;
	}
}

@media only screen and (max-width:780px) {
	.heading_details h3 {
		font-weight: 600;
		margin-bottom: 0.7rem;
		font-size: 1.2rem;

	}

	.heading_details h3 span {
		font-weight: 550;
		margin-bottom: 0.7rem;
		font-size: 1.4rem;
	}

	.heading_details li {
		font-weight: 400;
		margin-bottom: 0.4rem;
		font-size: 1rem;
		list-style: none;
	}

	.heading_details li b {
		font-size: 1.4rem;

	}

	.content-heading-div {
		padding: 0 0rem;
	}
}



@media only screen and (min-width:1700px) {
	.heading_img_div img {

		min-width: 45%;
		max-width: 90%;
	}

	.heading_details h3 {
		font-weight: 600;
		margin-bottom: 2rem;
		font-size: 3.5rem;
		color: #009cdb;
	}

	.heading_details h3 span {
		display: block;
		color: white;
		font-size: 2.5rem;
	}

	.heading_details li {
		line-height: 1.8rem;
		font-weight: 400;
		margin-bottom: 0.6rem;
		font-size: 1.5rem;
		list-style: none;
	}
}

@media only screen and (max-width:950px) {
	.headingbg {
		height: fit-content;
		min-height: 70vh;
		margin-top: 0;
		padding: 15vh 0 1rem 0;
	}

	.content-heading-div {
		padding-bottom: 6%;
	}
}


@media only screen and (max-width:580px) {
	.headingbg {
		min-height: 75vh;
		margin-top: 0;
		padding: 19vh 0 1rem 0;
	}

}

.service-img-container {
	display: flex
}

.service-img-container div {

	margin-right: 1rem;
}

.salesforcelogo {
	height: 3.5rem;
}

.service-icon {
	border-radius: 50%;

	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2rem;
	align-self: center;
}

.service-icon img {
	height: 2rem;
}

.service-contact-btn {
	margin: 1rem 0;
	padding: 1rem 4rem;
	background: #e44bc5;
	color: whitesmoke;
	border: none;
	border-radius: 0.2rem;
	font-weight: 500;
	color: white;
}

@media(max-width:980px) {
	.salesforcelogo {
		height: 5rem;
	}

	.service-icon {

		height: 3rem;
		width: 3rem;

	}

	.service-icon img {
		height: 2.5rem;
	}

	.heading_details h3 span {
		font-size: 3rem;
		margin: 1.5rem 0;
	}

	.heading_details li {

		font-size: 1.6rem;
		line-height: 2rem;
		margin: 1rem 0;
	}

	.heading_details li b {

		font-size: 1.8rem;

	}
}
.srv-pillersmain-div {
    /* position: absolute;
    top: -2rem; 
    left: 50%;
    transform: translateX(-50%);
    width: 2.5rem; */
    /* height: auto; */
	display: flex;
	color: #053ddb;
	font-size: 2rem;
	align-items: center;
	justify-content: center;
	/* opacity: 0.9; */
}
.pillar-text{
	font-weight: 700;
	font-size: 1.75rem;
    /* color: rgb(5, 61, 219); */
	text-align: center;
}

@media(max-width:580px) {
	.salesforcelogo {
		height: 4rem;
	}

	.service-icon {

		height: 2.5rem;
		width: 2.5rem;

	}

	.service-icon img {
		height: 2rem;
	}

	.heading_details h3 span {
		font-size: 2rem;
	}

	.heading_details li {

		font-size: 1.2rem;
		line-height: normal;

	}

	.heading_details li b {

		font-size: 1.3rem;
	}

	.btn-service-container {
		display: flex;
		justify-content: center;
	}

	.service-contact-btn {
		width: 100%;
	}
	.pillar-text{
		font-size: 1.2rem;
	}
}



.srv-pillersmain-main-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.5rem;
    align-items: end; 
    padding: 0 1rem;
    height: 20rem; 
}

.srv-pillersmain-div {
    width: 100%;
    position: relative; 
}
.srv-pillersmain-div{
	background-color:  #e8e8e89c;
}
.srv-pillersmain-div:nth-child(1) {
	height: 65%;
	color: rgb(5, 61, 219); 
    /* background-color: #bdbdbd9c; */
}

.srv-pillersmain-div:nth-child(2) {
	height: 80%;
    /* background-color: #bdbdbd9c; */
	color:      rgb(23, 43, 163);;
}

.srv-pillersmain-div:nth-child(3) {
	height: 95%;
    /* background-color:  #bdbdbd9c; */
	color: rgb(155, 30, 164);
}

.srv-pillersmain-div:nth-child(4) {

    background-color: rgb(16, 18, 162);
}

.serviceDetailBackgroundImage {
    background-image: url('../../../public/assets/BG/caseStudybg.png');
	background-repeat: no-repeat;
	background-position: center;
}
