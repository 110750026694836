.otherser_table {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 5px;
	color: #212121;
	border-radius: 10px;
}

.table_main_div {
	background-color: #14164b;
	border-radius: 2rem;
	padding: 2rem 3rem;
}

.otherser_table div {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	color: #212121;
}

.otherser_table div a {
	color: whitesmoke;
}

.table_main_div h2 {
	color: whitesmoke;
}

@media only screen and (max-width:920px) {

	.otherser_table {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		color: #212121;
	}

	.otherser_table div {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 10px;
		color: #212121;
	}

	.otherser_table div a {
		color: whitesmoke;
	}

	.table_main_div {
		padding: 2rem;
	}

	.table_main_div h2 {
		letter-spacing: 2px;
	}
}