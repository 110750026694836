.mobile-card-Heading{
    font-size: 1rem;
    font-weight: bold;
}
.heading-id-div{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom:  8px;
}
.heading-id-div p{
    margin: 0;
    font-size: 0.8rem;
}
.mobilesubDetail{
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}
.mobilesubDetail p{
    margin: 0;
}
.mobileassignToLogoStyle{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-size: 0.7rem;
    color: white;
    background-color: #1011a2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
