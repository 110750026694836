  .grid-container {
  	display: grid;
  	grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  	grid-gap: 50px;
  	padding: 1rem;
  	width: auto;
  	justify-items: start;
  	margin-right: 2rem;
  }

  .grid-item {
  	height: auto;
  	width: 6rem;
  }

  @media only screen and (max-width:768px) {
  	.credential-row {
  		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  	}

  	.grid-container {
  		justify-items: center;
  		padding: 1rem;
  		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  		margin-right: 0rem;
  	}
  }

  @media (max-width: 991px) {

  	.card {
  		margin-top: 20px;
  		margin-bottom: 20px;
  	}
  }

  @media (max-width: 768px) {

  	.card {
  		min-height: 300px;
  	}

  	.panel-body {
  		max-height: 200px;
  		font-size: 15px;
  		padding: 0px 5px;
  	}

  }