 .countupbg {
	margin-top: 0;
	height: 30vh;
	margin-top: 0;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main_section {
	margin:3rem 2.5rem ;
}

.inner-div {
	width: 100%;
	height: 100%;
	background-color: #14164b;
}

.countupbg_row {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width:920px) {
	.countupbg_row {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
	}
	.main_section {
	   margin: 2rem 0rem;
   }
}

.count-inner-div{
	padding: 1rem 0.5rem;
}
.countupbg_row .count-inner-div:nth-child(1){
	background-color: #0b5cab;
	color: white;

}

.countupbg_row .count-inner-div:nth-child(2){
	background-color: #eaf5fe;
	color: #032d60;
}
.countupbg_row .count-inner-div:nth-child(3){
	background-color: #032d60;
	color: white;
}
.count-inner-div h3{
	font-size: 3.5rem;
font-weight: bold;
}

.count-inner-div h2{
	font-size: 1.5rem;
    margin-bottom: 0.5rem;

}