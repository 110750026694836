.containerForLoader2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: 9999; 
  }
  
  .loader2 {
    border: 5px solid #f3f3f3;
    border-top: 5px solid rgb(16, 17, 162); 
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
  }
  
  .flex2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  