.setbg {
	margin-top: 0;
	/* background-image: url('../../../public/assets/BG/Connectbg.png'); */
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 50vh;
	display: flex;
	/* background-color: #f2f7f8; */
	align-items: center;
	justify-content: center;
	background-attachment: fixed;
}

.input_form {
	padding: 8rem 0.5rem;
}

.setbg_heading {
	color: #1012a2;
	font-size: 3rem;
	letter-spacing: normal !important;
}

.email_link,
.email_link span:hover {
	/* color: white; */
	border: none !important;
	text-decoration: none !important;
}

.inner-div {
	border-radius: 1rem;
}

.countupbg_row {
	text-align: center;
}

.countupbg_col {
	padding: 2rem 0;
}

.countupbg_col h2,
h3 {
	font-size: 2rem;
}

@media only screen and (max-width:920px) {

	.countupbg {
		height: 30vh;
	}

	.countupbg_row {
		margin-top: 8rem;
		text-align: center;
	}

	.countupbg_col {
		top: -2rem;
	}

	.countupbg_col h2,
	h3 {
		font-size: 1.5rem;
	}

}
.setMeeting-Div{
	padding: 3rem 0;
}

@media only screen and (max-width:620px) {

	.input_form {
		padding: 2rem 0.5rem;
	}
.btn-home-container{
	margin-top: 1rem;
}
.setMeeting-Div{
	padding: 2rem 0;
}
}

@media only screen and (max-width:920px) {

	.setbg {
		height: auto;
	}

	.setbg_heading {
		color: whitesmoke;
		font-size: 2rem;
		letter-spacing: 0;
	}

}

@media only screen and (max-width:1100px) {

	.countupbg {
		height: 50vh;
	}

	.countupbg_row {
		margin-top: 5rem;
		text-align: center;
	}

	.setbg {
		height: auto;
	}

	.setbg_heading {
		color: whitesmoke;
		font-size: 2rem;
		letter-spacing: 0;
	}
}

.discuss-form {
	width: 100%;
}

.discuss-form input {
	width: 80%;
	padding: 0.2rem 0.5rem;
	margin: 0.5rem 0;
	border: none;
	border-radius: 3px;
	color: #B8B88B;
}

.discuss-form-div {
	display: flex;
	align-items: flex-end;
	justify-content: right;
	padding-right: 20%;
}

.discuss-form button {
	margin-top: 0.5rem;
	display: block;
	padding: 0.5rem 1rem;
	border: none;
	background-color: #0062cc;
	color: white;
	border-radius: 5px;
}

@media screen and (max-width: 480px) {
	.btns2 {
		width: 100%;
	}

	.discuss-form input {
		width: 100%;
		padding: 0.2rem 0.5rem;
		margin: 0.5rem 0;
		border: none;
		border-radius: 3px;
	}

	.discuss-form-div {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 0;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.btns2 {

	margin: 0.5rem 0;
	/* padding: 0.5rem 2rem; */
	padding: 1rem 4rem;
	background: #fcd84a;
	color: whitesmoke;
	border: none;
	border-radius: 0.2rem;
	font-weight: 500;
	color: black;
}

.btns2 a {
	text-decoration: none;
	border: none;
	color: black;
}
