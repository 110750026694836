.section-row2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px;
	color: #212121;
}

.areadexp-grid-div {
	display: grid;
	grid-template-columns: repeat(4, 1fr);

}

@media only screen and (max-width:768px) {
	.areadexp-grid-div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

	}

}

@media only screen and (max-width:768px) {
	.section-row2 {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
		grid-gap: 30px;
		color: #212121;
	}
}

.sarea {
	box-shadow: 0 2px 8px -2px hsla(0,0%,9%,.08), 0 8px 12px -2px hsla(0,0%,9%,.16);
    border-radius: 10px;
    color: #052e61;
    font-weight: 500 !important;
    background: #ffffff;
    cursor: pointer;
    font-size: 16px;
    /* padding: 25px 10px; */
    text-align: center;
    transition: transorm .5s;
	place-items: center;
	padding: 2rem 1.5rem;
}

.sarea h2 {
	/* font-weight: 800;
    font-size: 1rem;
    margin-bottom: 0; */
    /* padding: 1rem; */
	font-weight: 800;
    font-size: 1rem;
    margin-bottom: 0;
    height: 100%;
    text-align: center;
    display: flex;
    /* padding: 1rem; */
    align-items: center;
    justify-content: center;
}





@media only screen and (max-width:768px) {

	Table tr:Second-child {
		border-bottom: none
	}

	Table th:nth-child(even) {
		border-right: none
	}

	Table tr {
		padding: 0px;
	}
	.sarea p {
		text-align: center;
		width: 100%;
		border-radius: 5px;
		font-size: 13px;
	}

}

@media only screen and (max-width:920px) {

	
}

Table td {
	padding: 1em 10px 1em 1em;
	border-right: 0.5px solid #333acc59;
	text-align: center;
}


hr {
	border-top: #333acc59 0.5px solid;
}

.heading {
	padding: 0;
}

@media only screen and (max-width:620px) {

	.sarea p {
		text-align: center;
		font-size: 10px;
	}
}

@media(max-width:1110px) {

	.services-area-expertise-grid{
		margin:3rem 0;
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-row-gap: 1rem;
		grid-column-gap: 1rem;
	}

}

.services-area-expertise-grid{
	/*
	margin:3rem 0;
	 display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-row-gap: 2px;
	grid-column-gap: 2px; */
	grid-row-gap: 15px;
    grid-column-gap: 15px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 3rem 0;
}
@media only screen and (max-width:920px) {
	.services-area-expertise-grid{
		margin:3rem 0;
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-row-gap: 1rem;
		grid-column-gap: 1rem;
	}
}
@media only screen and (max-width:750px) {

	.services-area-expertise-grid{
		margin:3rem 0;
		display: grid;
		grid-template-columns: repeat(1,1fr);
		grid-row-gap: 1rem;
		grid-column-gap: 2px;
	}
	.sarea {
	
		padding:1.5rem;
		
	}
	.heading {
		margin-top: 1rem;
		color: #101240;
		font-weight: 700;
		/* letter-spacing: 5px; */
		font-size: 1.7rem;
		margin-bottom: 1rem;
	}
}
.heading_area{
	letter-spacing: normal;
}