.adminPanelMrm {
    padding-bottom: 3rem;
}

.adminPanelMrmTable {
    /* width: 70%; */
    border-collapse: collapse;
}

.adminPanelMrmTable th,
.adminPanelMrmTable td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.adminPanelMrmTable th {
    background-color: #f2f2f2;
}

.adminPanelMrmHeading-div {
    padding: 0 1rem;
}

.adminPanelMrmHeading-inner-div {

    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 0.5rem;
    margin: 0.5rem 0;

}

.discriptionhtml p {
    margin: 0;
}

.adminPanelMrmHeading-inner-div {}

.heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.input {
    margin-bottom: 10px;
}

.input label {
    margin-right: 10px;
}

.input input {
    padding: 5px;

}


.adminPanelMrm-main-div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 80vh;
    padding: 0 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.2rem;
        /* Set custom width */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 0.2rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.adminPanelMrm-card-detail-div {

    height: 80vh;
    overflow-y: scroll;
    position: sticky;
    top: 0;
    background-color: white;

    &::-webkit-scrollbar {
        width: 0.1rem;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 0.2rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.updateStatus-btn {
    padding: 0.3rem 0.5rem;
    background-color: #1011a2;
    border-radius: 0.2rem;
    width: 3;
    border: none;
    color: white;
    margin-top: 0.3rem;

}

.adminPanelMrm-discription-user-date-div {
    display: flex;

    color: black;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;

}

.input-hrm_div {
    border: none;
    justify-content: right;
    margin: 0.5rem 0;
}

.ref-btn-div {
    width: 20%;
}

.ref-btn-div button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #2d58ed;
    color: white;
}

.comment-card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.6rem 0 0.3rem 0;
    border-radius: 4px;

    overflow-wrap: anywhere;
}

.comment-user-date {
    font-size: 0.7rem;
}

.comment-content {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.discriptionhtml {
    overflow-wrap: anywhere;
}

.discriptionhtml img {
    width: 100%;
}

.cls-1 {
    fill: none
}

.dueToday {
    background-color: #ffcaca;
    font-weight: 500;
    width: 100%;
    color: black;
}

.messageofHrm {
    position: absolute;
    top: 3vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.messageofHrm div {

    z-index: 99999;
    padding: 0.7rem 2rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: white;

    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.success {
    background-color: #4caf50;
}

.error {
    background-color: red;
}

.css-b62m3t-container {
    color: black !important;
}

.tags {
    margin: 0;
    border-bottom: 1px solid #ccc;
    padding: 0.7rem 0.5rem;
    position: sticky;
    background-color: white;
    top: 0;
    color: black;
    z-index: 9;
    list-style-type: none;
    display: flex;
    gap: 0.4rem;
    /* margin-bottom: 1rem; */
    justify-content: space-between;
}

.tag {

    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 0.8rem;
    border-radius: 100rem;
    box-shadow: 0 0 0 2px #d0dffc;
    background-color: transparent;
    color: var(--dark-color);
    font-weight: bold;
    transition: background-color 250ms ease,
        box-shadow 250ms ease;
}

.tag1 {


    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 100rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--dark-color);
    font-weight: bold;
    transition: background-color 250ms ease,
        box-shadow 250ms ease;
}

.load-text {
    margin-top: 20px;
    font-size: 16px;
    color: #000;
}


.circlLogoStyle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: white;
    background-color: #1011a2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refreshBtn {
    color: white;
    background-color: red;
    border: none;
    padding: 0.3rem 1rem;
    border-radius: 0.2rem;

}

.discriptionhtml li {
    color: #000;
}



.hrmBtn {
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 0.3rem;
    color: black;
    margin: 0 0.5rem;
    font-size: 1.2rem;
    color: white;
}

.hrm-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
}

.hrm-button-div:hover {
    border: none;
    text-decoration: none;
}

.color-forTaskBtn {
    font-weight: 500;

    color: #1011a2;
    background-color: white;

}

.logout-btn-color {

    font-weight: 500;
    color: red;
    background-color: white;
}

.centered-window {
    border: 1px solid #ccc;
    width: 30%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 999999999;
}

.formForWindoDiv {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    z-index: 99999;
}

.formForWindoDiv button {
    color: white;
    background-color: #1011ab;
    border: none;
    padding: 0.3rem;
    margin-top: 2rem;
}

.formForWindoDiv input {
    width: 100%;
    padding: 0.3rem;
    margin-top: 0rem;
}

.selected {
    background-color: #d3e3fd;
}

.actualhourWindowCloseDiv {
    position: absolute;
    right: 0;
    display: flex;
    padding: 0.5rem;
    justify-content: right;

}

.actualhourWindowCloseDiv svg {
    height: 1.5rem;
    width: 1.5rem;
}

.searchDiv {
    display: flex;
}

.searchDiv input {
    border-radius: 0.3rem;
    padding: 0.3rem 0.5rem;
    border: 1px solid #cccccc;
}

.searchDiv div {
    border: 1px solid #ccc;
    margin-left: 0.3rem;
    border-radius: 0.3rem;
}

.searchDiv div svg {
    height: 2rem;
}

.css-wsp0cs-MultiValueGeneric {
    font-size: 65% !important;
}

.search-div {
    position: relative;
}

.suggestions-list {
    position: absolute;
    top: 100%;
    overflow-y: scroll;
    max-height: 15rem;
    padding: 01rem 0.5rem;
    width: 100%;
    color: black;
    background-color: white;
    z-index: 99999;
    margin: 0;
}

.suggestions-list li {
    padding: 0.3rem 0;
    color: black;
}

.suggestions-list li:hover {
    background-color: #e6e6e6;
}

@media (max-width: 767px) {
    .adminPanelMrm {
        width: 100vw;
    }

    .hrmAdminPanelNavbar {
        display: flex;
        /* flex-wrap: wrap; */
    }

    .hrmBtn {
        padding: 0.3rem 0.5rem;
        margin: 0 0.1rem;
    }

    .hrmAdminPanelNavbar h1 {
        font-size: 1.2rem;
    }

    .color-forTaskBtn {
        font-weight: normal;
        font-size: 1rem;
        border: 1px solid #1011a2;
        color: #1011a2;
        background-color: white;
    }

    .hrmNavLeftDiv {
        /* width: 100%; */
        display: flex;
    }

    .hrmAdminPanelNavbar {
        width: 100%;
        padding: 0rem 0.3rem;
    }

    .adminPanelMrmHeading-div {
        padding: 0 0.5rem;
        width: 100vw;
        background-color: white;
    }

    .adminPanelMrmHeading-inner-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white !important;
        ;
        gap: 0rem;
    }

    .input-hrm_div {
        border: none;
        width: 100%;
        margin: 0.3rem 0;
    }

    .adminPanelMrm-main-div {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 0 0.5rem;
        width: 100vw;
    }

    .adminPanelMrmTable {
        width: 100%;
        overflow-x: scroll;
        background-color: white;
    }

    .detailsDivHrm {
        position: relative;
    }

    .hrmCardDetailDiv {
        position: relative;
    }
}

.displayNone {
    display: none;
}

.removeCardOnDetail {
    position: absolute;
    right: 1rem;
    top: 0;
    display: flex;
    float: right;
    background-color: #ccc;
}

.removeCardOnDetail svg {
    height: 2rem;
}

.input-hrm_div {
    z-index: 99 ;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 27px;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4caf50;
}

input:checked+.slider:before {
    transform: translateX(24px);
}

.toggle-label {
    color: black;
    font-size: 11px;
}

.detail-view-forCard {
    position: absolute;
}

.backgroundColor {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kanbanCardDetail {
    border: 1px solid #ccc;
    width: 80%;
    height: 88%;
    position: fixed;
    top: 54vh;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 99;
}

.adminPanelMrm-kanban-card-detail-div {
    width: 100%;
    height: 100%;
}

.ClosedDivForKanbanDetail {
    position: absolute;
    right: 0.5rem;
    display: flex;
    top: 0rem;
    padding: 0.2rem;
    justify-content: right;
    /* background-color: #ccc; */
    border: 1px solid #ccc;
    z-index: 99;
    cursor: pointer;
}

.ClosedDivForKanbanDetail svg {
    height: 1.5rem;
    width: 1.5rem;
}



.EntityTreeDiv {
    margin: 0rem 0 1rem 0;
    padding: 0 0.3rem;
    background-color: #f9f9f9;
    padding: 0.1rem 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.chilEntityDiv {
    list-style-type: none;
    padding: 0;
    margin: 0.3rem 0;
    cursor: pointer;
}

.chilEntityDiv {
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 0 0.3rem;
}

.chilEntityDiv p {
    margin: 0.2rem 0;
}

.chilEntityDiv .taskDetailTaskId:hover {
    color: #1011a2;
}

.chilEntityDiv p:nth-child(1) {
    cursor: pointer;
}

.taskDetailTaskId {
    font-size: 0.8rem;
    font-weight: bold;
    min-width: fit-content;
    display: flex;
    align-items: flex-end;
}

.taskDetailTitle {
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.assignToLogoStyle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-size: 0.8rem;
    color: white;
    background-color: #1011a2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.innerchildTaskDetails {
    display: grid;
    grid-template-columns: 75% 24%;
    gap: 0rem;
}

.taskDetailRealatedHeading {
    margin: 0;
    font-size: 1rem;
    margin: 0.7rem 0;

}

.chilEntityDiv:hover {
    background-color: #d3e3fd;
}

.leftInnerchildTaskDetails {
    display: flex;
    gap: 1rem;
}

.assign-to-Div {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
}

.taskrecordType {
    cursor: pointer;
    text-align: center;
    font-size: 0.75rem;
}

.svg-outer-div svg {
    height: 1.5rem;
    width: 1.5rem;
}

.table-header-fix {
    position: sticky;
    top: -0.2px;
    z-index: 1;
}

.smallLoader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid rgb(16, 17, 162);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;

}

.grid_detail_of_Task {
    margin-top: 0.7rem;
    display: grid;
    grid-template-columns: calc(60% - 1rem) 40%;
    grid-column-gap: 1rem;
}

.innerGridTaskDetail {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.innerGridTaskDetail1 {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}


.dueDateDiv {
    margin: 0;
    display: grid;
    grid-template-columns: 45% calc(55% - 0.5rem);
    gap: 0.5rem;
}

.dueDateDiv h4,
h6 {
    margin: 0;
}

.dueDateDiv,
h6 {
    margin: 0;
    text-align: center;
    font-weight: 100;
}

.dueDateDiv h6,
h4 {
    display: flex;
    align-items: center;
}

.dueDateDiv p:nth-child(2) {
    margin: 0;
    font-size: 0.9rem;
}

.discriptionOfTaskDiv {
    border: 1px solid #ccc;
    padding: 0.5rem;
    min-height: 20rem;
    background-color: white;
}

.taskDetailDiscription {
    margin: 0;
    font-size: 1rem;
    margin: 0.7rem 0;

}

.innerTags {
    display: flex;
    gap: 0.5rem;
}

.svgForTag svg {
    height: 2rem;
    width: 2rem;
}

.dueDateDiv1 {
    margin: 0;
    display: grid;
    grid-template-columns: 30% calc(70% - 0.5rem);
    gap: 0.5rem;
}

.centerdueDateDiv1 {
    margin-bottom: 0.5rem;
}

.centerdueDateDiv1 h6 {
    margin-bottom: 0.3rem;
    text-align: left;
    color: #42526e;
}

.centerdueDateDiv1 h4 {

    margin: 0;
}

.dueDateDiv1 h6 {
    text-align: left;
    margin: 0;
}

.dueDateDiv1 h4 {

    margin: 0;
}

.cursorClass {
    cursor: pointer;
}

.ProseMirror {
    background-color: white;
}

.dueDateDivKanbanCard {
    font-size: .8rem;
    margin: 0;
    grid-gap: .5rem;
    display: grid;
    gap: .5rem;
    grid-template-columns: 50% calc(50% - 0.5rem);
    text-align: left;
}

.nameShowDiv {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: #669cf8;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}


.scrollClassLeft {
    height: 100%;
    width: 25%;
    position: sticky;
    overflow-y: scroll;
    top: 90px;
    /* height: calc(100vh - 200px); */
    padding: 1rem;

    &::-webkit-scrollbar {
        width: 0.3rem;
        /* Set custom width */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 0.2rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.editorImageWidthTextEditor {
    width: 100% !important;
}

.comment-card img {
    width: 100% !important;
}
.filterButtonMobileView{
    display: none;
}

.heightZeroClass {
    /* height: 0; */
    /* overflow: hidden; */
    /* transition: height 0.3s ease; */
}

.input-hrm_div {
    height: auto;
    /* overflow: hidden; */
    /* transition: height 0.3s ease; */
}


/* .css-1nmdiq5-menu{
    z-index: 9999999999 !important;
    height: 100%;
} */