.left-div{
    position: sticky;
    margin-top: 1rem;
    width: 17%;
    display: flex;
    padding: 0 1rem;
    flex-direction: column;
}
.left-div h1{
    cursor: pointer;
    margin: 0.5rem 0;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 400;
    color: #1012a2;
}
@media (max-width: 768px){
    .left-div{
        display: none;
    }
}