* {
	padding: 0;
	margin: 0;
}

.f-row {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}

.footer-section {
	padding: 50px;
	color: black;
	background: #c4c7c554;
	position: relative;
}

.footer-cta {
	border-bottom: 1px solid #373636;
}

.single-cta i {
	color: #ff5e14;
	font-size: 30px;
	float: left;
	margin-top: 8px;
}

.cta-text {
	padding-left: 15px;
	display: inline-block;
}

.cta-text h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 2px;
}

.cta-text span {
	color: #757575;
	font-size: 15px;
}

.footer-content {
	position: relative;
	z-index: 2;
}

.footer-pattern img {
	position: absolute;
	top: 0;
	left: 0;
	height: 330px;
	background-size: cover;
	background-position: 100% 100%;
}

.footer-logo {
	margin-bottom: 30px;
}

.footer-logo img {
	max-width: 200px;
}

.footer-text p {
	margin-bottom: 18px;
	font-size: 14px;
	color: black;
	line-height: 28px;
}

.footer-social-icon span {
	color: #fff;
	display: block;
	font-size: 20px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 20px;
}

.footer-social-icon a {
	color: #fff;
	font-size: 16px;
	margin-right: 15px;
}

.footer-social-icon i {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 38px;
	border-radius: 50%;
}

.instagram-bg {
	background: #d62976;
}

.twitter-bg {
	background: #55ACEE;
}

.linkedin-bg {
	background: #2d58ed;
}

.facebook-bg {
	background: #316FF6;
}
.footer-widget-heading h3 {
	color: black;
	font-size: 20px;
	letter-spacing: 3px;
	font-weight: 600;
	margin-bottom: 40px;
	position: relative;
}

.footer-widget-heading h3::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -15px;
	height: 2px;
	width: 50px;
	background: #2d58ed;
}

.footer-widget ul li {
	display: inline-block;
	
	width: 50%;
	margin-bottom: 12px;
}

.footer-widget ul li a:hover {
	color: #2d58ed;
}

.footer-widget ul li a {
	color: black;
	text-transform: capitalize;
	font-size: medium;
}

.subscribe-form {
	position: relative;
	overflow: hidden;
}

.subscribe-form input {
	width: 100%;
	padding: 14px 28px;
	background: #2E2E2E;
	color: #ccc;
}

.subscribe-form button {
	position: absolute;
	right: 0;
	background: #2d58ed;
	padding: 13px 20px;
	border: 1px solid #2d58ed;
	top: 0;
}

.subscribe-form button i {
	color: #fff;
	border: none;
	font-size: 22px;
	transform: rotate(-6deg);
}

.copyright-area {
	background: #202020;
	padding: 25px 0;
}

.copyright-text p {
	text-align: end;
	margin: 0;
	font-size: 14px;
	color: black;
}

.copyright-text p a {
	color: #ff5e14;
}

.footer-menu li {
	display: inline-block;
	margin-left: 20px;
}

.footer-menu li:hover a {
	color: #ff5e14;
}

.footer-menu li a {
	font-size: 14px;
	color: #878787;
}

.input .touched.invalid {
	border-color: red;
	background: #ffc2c2;
  }
@media(max-width:720px) {
	.subscribe-form button {
		position: absolute;
		right: 0;
		background: #2d58ed;
		padding: 2px 20px;
		border: 1px solid #2d58ed;
		top: 0;
	}

	.footer-social-icon i {
		height: 33px;
		width: 33px;
		text-align: center;
		line-height: 10px;
		border-radius: 50%;
	}

	.footer-social-icon a {
		color: #fff;
		font-size: 12px;
		margin-right: 7px;
	}

	.copyright-text p {
		text-align: end;
		margin: 0;
		font-size: 0.9rem;
		color: #878787;
	}
}