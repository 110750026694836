

.carousel-caption {
	scroll-behavior: smooth;
	position: initial;
	z-index: 10;
	padding: 5rem 8rem;
	color: black;
	text-align: start;
	font-size: 1.2rem;
	line-height: 2rem
}
.testimonial{
	width: 100%;
}

@media(max-width:767px) {
	.carousel-caption {
		position: initial;
		z-index: 10;
		padding: 3rem 2rem;
		color: black;
		text-align: center;
		font-size: 0.7rem;
		line-height: 1.5rem
	}
}

.carousel-caption img {
	width: 6rem;
	border-radius: 5rem;
	margin-top: 2rem
}

@media(max-width:767px) {
	.carousel-caption img {
		width: 4rem;
		border-radius: 4rem;
		margin-top: 1rem
	}
}

#image-caption {
	font-style: normal;
	font-size: 1rem;
	margin-top: 0.5rem
}

@media(max-width:767px) {
	#image-caption {
		font-style: normal;
		font-size: 0.6rem;
		margin-top: 0.5rem
	}
}

.butn {
	background-color: rgb(16 18 162);
	padding: 1.4rem
}

@media(max-width:767px) {
	i {
		padding: 0.8rem
	}
}

.carousel-control-prev {
	justify-content: flex-start
}

.carousel-control-next {
	justify-content: flex-end
}

.carousel-control-prev,
.carousel-control-next {
	transition: none;
	opacity: unset;
}

@media(max-width:580px) {
	.carousel-caption {
		padding: 2rem 3rem;		
	}
	.butn {
		background-color: rgb(16 18 162);
		padding: 0.7rem;
	}
}