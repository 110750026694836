.filepinputfield {
    display: flex;
}
.inputfieldbutton{
    justify-content: center;
    align-items: center;
    display: flex;
}
.inputfieldbutton div{
    width: 2rem;
}
.inputfieldbutton svg{
    height: 1rem;
    width: 1rem;
}
.input {
    margin: 1rem 0;
    width: 100%;
  }
  
  .input label {
    display: block;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }
  
  .input input,
  .input textarea {
    display: block;
    font: inherit;
    padding: 0.25rem 0.5rem;
    width: 97%;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .input .touched.invalid {
    border-color: red;
    background: #ffc2c2;
  }
  
  .input input:focus,
  .input textarea:focus {
    outline: none;
    border-color: #3b0062;
    color: #3b0062;
  }