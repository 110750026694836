.leave-options p,
.datePickerDiv p,
.reason-textarea label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
}

.leave-options {
    color: black;
    margin-bottom: 20px;
}

.leave-options input {
    margin-right: 10px;
}

.leave-options label {
    margin-right: 20px;
}

.datePickerDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.datePickerDiv>div {
    flex: 1;
    margin-right: 10px;
}

.datePickerDiv>div:last-child {
    margin-right: 0;
}

.reason-textarea {
    margin-top: 20px;
}

.reason-textarea textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

.reason-textarea textarea::placeholder {
    color: black;
}



.leave-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.leave-table thead tr {
    background-color: #f2f2f2;
    ;
    color: black;
    text-align: left;
}

.leave-table th,
.leave-table td {
    padding: 12px 15px;
}

.leave-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.leave-table tbody tr:nth-of-type(4) {
    background-color: #f3f3f3;
}

.leave-table tbody tr:last-of-type {
    border-bottom: 2px solid #f2f2f2;
    ;
}

.leaveFormBtnDiv {
    margin: 1rem 0;
}

.scrollableClassTable {
    height: 50vh;
    overflow-y: scroll;
}

.leaveFormBtnSection {
    padding: 0.2rem 1rem;
    margin-right: 1rem;
    border: none;
    border-radius: 0.3rem;
    border: 2px solid #1011a2;
    background-color: white;
    color: #1011a2;
    font-size: 1rem;
}

.leavebtnShowSection {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 1rem 0 0rem 0;
}

.approvedBtn {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    color: white;
    border-radius: 0.3rem;
    border: none;
    background-color: rgb(42, 192, 28);
}

/* for the status of old Leave  */
.leave-table {
    width: 100%;
    border-collapse: collapse;
}

.leave-table th,
.leave-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.leave-table th {
    background-color: #f2f2f2;
}

.leave-table .leave-type {
    width: 15%;
}

.leave-table .start-date,
.leave-table .end-date {
    width: 15%;
}

.leave-table .reason {
    width: 40%;
}

.leave-table .status {
    width: 15%;
    text-align: center;
}

.leave-table .status.approved {
    color: green;
}

.leave-table .status.reject {
    color: red;
}

.containerSelf {
    width: 50%;
    margin: auto;
}

.closeBtn-divLeaveForm {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.closeBtn-divLeaveForm svg {
    height: 2rem;
}

.leaveFormMainContainer {
    background-color: #efefef;
    padding: 2rem 0;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.leave-tableBalance {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
}

.gridLeaveBalance {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid #ddd;
    box-sizing: border-box;
}



.gridLeaveBalance p {
    margin: 0;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ddd;
}

.gridLeaveBalance p:last-child {
    border-right: none;
}

@media(max-width:780px) {
    .containerSelf {
        width: 90%;
        margin: auto;
        margin-top: 1rem;
    }
    .leaveFormBtnDiv{
display: flex;
gap: 1REM;
    }
    .gridLeaveBalance p {
        font-size: 0.8rem;
    }

    .leaveFormBtnSection {
        width: 50%;
        margin: 0;
      
    }
    .leavebtnShowSection{
        margin: 0;
    }
}