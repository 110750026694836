.slick-dots
{
	/* display: none !important; */
}
.Case-main-div {
	width: 100%;
	min-height: 100vh;
	background-image: url("./../../assets/main_bg_02.png");
	background-repeat: none;
	background-size: cover;
	padding: 20vh 0 0 0;

}

.container-class {
	width: 100%;
	display: flex;
	margin: 0;
}

.inner-detail_card {
	padding: 6rem 0;
	margin: 0;
}

.detail_card {
	width: 40%;
	height: 100%;
	margin: 0;
}

.slider-div {
	width: 70%;
	height: 100%;
}

.slider-div {
	height: 100%;
	padding: 4rem 0
}

.slick-slide>div {
	padding: 0 5px;
}

.slick-list {
	margin: 0 -5px;
}

.slick-slide {
	opacity: 0.4;
}

.slick-current {
	opacity: 1;
	transform: scale(1.05);
	z-index: 99;
	
}

.slick-current .xyz {
	transform: scale(0.8);
	opacity: 1.1;

}

.card {
	border: 1px solid #fff;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	height: auto;
	color: black;
	cursor: pointer;
	scale: 0.96;
	min-height: 19rem;
	position: relative;
}

.card-top {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-top>img {
	margin: 1px 0;
	/* object-fit: cover !important; */
	background: black;
	width: 100%;
	min-height: 10rem;
	max-height: 10rem;
}

.card-bottom {
	padding: 0.5rem 20px;
}

.card-bottom h6 {
	font-size: 1rem;
	font-weight: 600;
}

.card-bottom p {
	font-size: 0.7rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
}

.view-btn {
	height: 2.2rem;
	margin-bottom: 0.5rem;
	border: 2px;
	background-color: #2D58ED;
	color: white;
	border: none;
}

.view-btn:hover {
	background-color: #4F4FC6;
	color: white;
	border: none;
}

.view-btn a {
	text-decoration: none;
	color: white;
}

.para-limit1 {
	--max-lines: 3;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
}

.para-limit {
	--max-lines: 2;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
}

.case-card-button-div{
	width: 100%;
	position: absolute;
	bottom: 0;
	padding: 0.5rem 20px;
}
@media only screen and (max-width:920px) {

	.Case-main-div {
		width: 100%;
		height: 100vh;
		padding-top: 20vh;
		overflow: hidden;
	}

	.detail_card {
		display: none;
		width: 0;
	}

	.container-class {
		width: 100%;
	}

	.slider-div {
		height: 100%;
		width: 90vw;
		overflow: hidden;
	}

	.card-bottom h6 {

		font-weight: 400;
	}

}

@media only screen and (max-width:720px) {

	.Case-main-div {
		width: 100%;
		height: 100vh;
		padding-top: 20vh;
		overflow: hidden;
	}

	.detail_card {
		display: none;
		width: 0;
	}

	.container-class {
		width: 100%;
	}

	.slider-div {
		height: 100%;
		width: 90vw;

	}

	.card-bottom h6 {
		font-weight: 400;
	}

}

@media only screen and (max-width:600px) {

	.card {
		width: 60vw;
		border: 1px solid #fff;
		background: white;
		border-radius: 8px;
		overflow: hidden;
		height: auto;
		color: black;
		cursor: pointer;
	}

	.slider-div {
		width: 70vw;
		margin: 0 15vw;
	}
}


@media only screen and (max-width:520px) {

	.Case-main-div {
		width: 100%;
		height: 100vh;
		padding-top: 20vh;
		overflow: hidden;
	}

	.detail_card {
		display: none;
		width: 0;
	}

	.container-class {
		width: 100%;
	}

	.slider-div {
		height: 100%;
		width: 90vw;
		margin: 0;
	}

	.card-bottom h6 {
		font-weight: 400;
	}

}

.slick-dots{
	display: none !important;
}