/* .blog-detail-view-image-div {
    padding-right: 3rem;
}

.blog-detail-view-image {
    max-width: 60rem;
    width: 50%;
}

.blogDatial-heading {
    color: black;
    font-size: 2rem;
    letter-spacing: 0.01em;
    padding: 1.5rem 0 0.5rem 0;
    cursor: pointer;
    font-weight: 700;
}


.authors{
    display: flex;
    align-items: center;
    margin-bottom: 0rem;
    margin-top: 0rem;
}
.author-pictures {
    display: flex;
    margin-right: 1rem;
}

.author-pictures .image {
    border-radius: 50%;
    width: 5.2rem;
    height: 5.2rem;
    background-size: cover;
    background-position: center;
}

.authors .info {
    color: black;
    font-size: 0.8rem;
}
.authors .info p{
    margin: 0;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
} */


.blog-detail-view-image-div {
    padding-right: 3rem;
}

.blog-detail-view-image {
    max-width: 60rem;
    width: 50%;
}

.blogDatial-heading {
    color: #1012a2;
    font-size: 2rem;
    letter-spacing: 0.01em;
    padding: 1rem 0 0.5rem 0;
    cursor: pointer;
    font-weight: 700;
}


.authors{
    display: flex;
    align-items: center;
    margin-bottom: 0rem;
    margin-top: 0rem;
}
.author-pictures {
    display: flex;
    margin-right: 1rem;
}

.author-pictures .image {
    border-radius: 50%;
    width: 5.2rem;
    height: 5.2rem;
    background-size: cover;
    background-position: center;
}

.authors .info {
    color: black;
    font-size: 0.8rem;
}
.authors .info p{
    margin: 0;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.blog_html_css{
    border-bottom: 1px solid #ccc;
}
/* .blog_html_css  li{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.blog_html_css   ul{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
}

.blog_html_css  ol{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
}
.blog_html_css  > li span{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-size: 1.1rem;
}

.blog_html_css  p{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
}

.blog_html_css p span{
    font-family: Arial, Helvetica, Inconsolata;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
} */