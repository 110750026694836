.roiouterSection{
    padding: 3rem 0rem;
}
.roiServiceSetction{
    /* width: 75%; */
    /* border-left: 1px solid #ccc; */
    position: relative;
    margin: 3rem 0;
    /* border-left: 2px solid #ccc; */
}
.roiServiceSetction-inner-div{
    padding-left: 2rem;
    margin:1.5rem 0 ;
    position: relative;
    
}

.roiServiceSetction-inner-div h3{
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #e44bc5;
}
.roiServiceSetction-inner-div h2{
    margin: 0.3rem 0;
    color: black;
    font-size: 1rem;
}
.roiServiceSetction-inner-div:nth-child(1){
    
    
    margin-top: 0;
}
/* .roiServiceSetction-inner-div:nth-child(2)::after{
    
    
    background-color:rgb(23, 43, 163) ;
} */

.roiServiceSetction-inner-div:nth-child(3){
    
    
    margin-bottom: 0;
}
/* .roiServiceSetction-inner-div:nth-child(3)::after{
    
    
    background-color: rgb(155, 30, 164);
} */
.roiServiceSetction-inner-div::after{
    content: '';
    position: absolute;
    height: 104%;
    top: 0;
    left: -0.2%;
    width: 4px;
    background-color: #fcd84a;
}
