.loader-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  /* .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  } */
  
  .flex {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
} 
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid rgb(16, 17, 162);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}