.rightDetailMainDivSection {
    width: 75%;
    padding: 2rem 1rem;
}

.leftDetailMainDivSection {
    width: 25%;
}

.sliderBtnDIv {
    display: none;
}

.rightSliderOpenDiv {
    display: none;
}

.assignToLogoStyleNew svg {
    width: 2rem;
    height: 2rem;
}

.hrmHeadingStyle {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
}

.HrmsubHeading {
    font-weight: bold;
    font-size: 1rem;
}

.editor-button svg {
    height: 1.2rem;
}

.backLogo {
    display: none;
}

@media(max-width:980px) {
    .kanbanCardDetail {
        width: 95%;
    }

    .rightDetailMainDivSection {
        width: 100%;
        padding: 1rem 0.5rem;
    }

    /* .leftDetailMainDivSection */

    .leftDetailMainDivSection {
        width: 0%;
        padding: 0;
    }

    .sliderBtnDIv {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .sliderBtnDIv {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 0.5rem;
    }

    .rightSliderOpenDiv {
        display: block;
        position: absolute;
        /* bottom: -1.8rem; */
        right: 0.2rem;
        padding: 0 0.5rem;
        background-color: white;
    }

    .rightSliderOpenDiv svg {
        transform: rotate(180deg);
    }

    .kanbanCardDetail {
        top: 53vh;
        height: 90%;
    }

    .assignToLogoStyleNew svg {
        height: 1.5rem;
    }

    .assignToLogoStyleNew svg {
        height: 1.5rem;
    }

    .hrmHeadingStyle {
        font-size: 1rem;
        margin: 0;
        font-weight: bold;
    }

    .HrmsubHeading {
        font-size: 0.9rem;
    }

    .editor-button svg {
        height: 0.9rem;
    }

    .editor-button {
        font-size: 0.9rem;
    }

    .chilEntityDiv {
        display: grid;
        grid-template-columns: 85% 15%;
        padding: 0 0.3rem;
    }

    .discriptionOfTaskDiv {

        min-height: 15rem;
    }

    .input-hrm_div {
        z-index: auto;
    }

    .backLogo {
        display: block;
        margin-right: 0.5rem;
    }

    .filterButtonMobileView {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filterButtonMobileView button {
        border: none;
        padding: 0.2rem 0.5rem;
        cursor: pointer; 
        color: white;
        background-color: #1011a2;
        border-radius: 0.3rem;
    }
    .heightZeroClass{
       display: none;
    }
    
}

.displayWidth {
    display: none;
    width: 0;
    padding: 0;
}

.widthScreen {
    width: 100%;
    padding: 1rem 1rem;
}