/* hide scrollbar but allow scrolling */

.column {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.column::-webkit-scrollbar {
    display: none;
}
.detailHD{
    margin: 0;
    font-size: 0.9rem;
    font-weight: bold;
}
.detailHDHeading{
    margin: 0rem 0;
    font-size: 0.8rem;
}
.dueDateDiv{
    font-size: 0.8rem;
    margin: 0;
}
.dueDateDiv span{
font-size: 0.85rem;
}
.projectNameCard{
    font-weight: 500;
    margin: 0;
    margin-top: 0.3rem;
    font-size: 0.8rem;
}