.feed__status {
  width: 90%;
  margin: 1rem auto;
}

.feed__status form {
  display: flex;
  align-items: center;
}

.feed__status form * {
  margin: 0 0.5rem;
}

.feed__control {
  text-align: center;
}

.new-post__preview-image {
  width: 15rem;
  height: 7rem;
}

.right-main-div {
  padding: 0 1rem;
  margin-left: 25%;
  width: 70%;
}
@media (min-width: 768px) {
  .feed__status {
    width: 30rem;
  }
}