.newbanner-inner-div {
    width: 100%;
    padding: 0 10%;
 height: 75vh;
    background-color: white;
}

.newbanner-row-div {
    height: 100%;
    display: grid;
    grid-template-columns: calc(55% - 1rem) 45%;
}

.newtopHeaderHeading {
    /* color: black;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 0.8px;
    display: block; */
    color: #000000;
    font-size: 2rem;
    font-weight: lighter;
    letter-spacing: 1px;
    display: block;
    line-height: 3rem;
}

.newbanner {
    margin-top: 0;
    max-height: 75vh;
    padding: 145px 0 0px 0;
    background-color: white;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.newbtns {
    /* margin: 2.5rem 0;
    padding: 1.3rem 4rem;
    background: #fcd84a;
    color: black;
    border: none;
    border-radius: 0.2rem;
    font-weight: 500; */
    margin: 2.5rem 0;
    padding: 1.3rem 4rem;
    background: #1012a2;
    color: #ffffff;
    border: none;
    border-radius: 0.2rem;
    font-weight: 500;
}

.newbtns a {
    text-decoration: none;
    color: white;
}

.newbtns:hover {
    color: whitesmoke;
}

.newbanner-right-div {
    background-color: #f2f7f8;
    padding: 145px 0 0px 0;
    max-height: 75vh;
}

.newbanner-right-innerdiv {

    padding: 1rem 2rem;
}

.newimplemantationDomHeading {
    color: rgb(0, 54, 219) !important;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.newbannerServiceSetction-inner-div {
    margin: 1rem 0;
    /* display: flex; */
    gap: 1rem;
}

.newbannerServiceSetction-inner-div h3 {
    width: 30%;
    margin: 0;
    margin-bottom: 0rem;
    font-size: 3rem;
    /* font-weight: bolder; */
    color: #1012a2;
}

.newspanClassForImplemantation {
    color: black;

    display: flex;
    align-items: center;
}

.newspanClassForImplemantation {
    color: rgb(0, 54, 219);
    font-size: 1.3rem;
    font-weight: 500;
}

.HeadingSpanClass {
    color: rgb(16 18 162);
    font-weight: 500;
    font-size: 2rem;
    line-height: 4rem;
}



@media(min-width:1700px ) {
    .newtopHeaderHeading {

        padding: 0 1rem;
        font-size: 3.5rem;
        /* font-weight: 700; */
        letter-spacing: normal;
        line-height: normal;
    }

    .HeadingSpanClass {
        color: rgb(16 18 162);
        font-weight: 500;
        font-size: 2.75rem;
        line-height: 4rem;
    }

    .newimplemantationDomHeading {

        font-size: 1.5rem;

    }

    .newbannerServiceSetction-inner-div h3 {

        font-size: 4.5rem;

    }
}

@media(min-width:1900px) {
    .newtopHeaderHeading {

        padding: 0 1rem;
        font-size: 3.2vw;
        /* font-weight: 700; */
        letter-spacing: normal;
        line-height: normal;
    }

    .HeadingSpanClass {
        color: rgb(16 18 162);
        font-weight: 500;
        font-size: 3.7vw;
        line-height: 4rem;
    }

    .newimplemantationDomHeading {

        font-size: 1.7vw;

    }

    .newbannerServiceSetction-inner-div h3 {

        font-size: 4vw;

    }
    .newspanClassForImplemantation{
        font-size: 1.6vw;
    }
    .newbtns{
        font-size: 2vw;
    }
}

@media(max-width:1200px) {
    .newbannerServiceSetction-inner-div h3 {
        width: 35%;
        margin: 0;
        margin-bottom: 0rem;
        font-size: 2.5rem;
        /* font-weight: bolder; */
        color: #1012a2;
    }

    .newspanClassForImplemantation {
        color: rgb(0, 54, 219);
        font-size: 1.1rem;
        font-weight: 500;
    }
}

@media (max-width: 1130px) {
    .newbanner-inner-div {
        /* height: 85vh; */
    }

    .newbanner-left-div {
        height: 45vh;
        display: flex;
        align-items: end;
        /* justify-content: center; */
        padding: 7rem 0 2rem;
    }

    .newbanner-right-div {
        /* height: 33vh; */
    }

    .newbanner-row-div {
        display: flex;
        flex-direction: column;
        gap: 2vh;
    }

    .newbanner {
        padding: 0rem 0 0px 0;
        max-height: 100%;
    }

    .newbanner-right-div {
        background-color: #f2f7f8;
        padding: 0rem 0 0px 0;
    }

    .newtopHeaderHeading {
        font-size: 2rem;
        margin-bottom: 1rem;

    }

    .newbtns {
        margin: 1rem 0rem 1.5rem 0;
        padding: 0.8rem 3rem;
        width: 100%;
        background: #1012a2;
        color: black;
        border: none;
        border-radius: 0.2rem;
        font-weight: 500;
    }

    .newimplemantationDomHeading {
        color: rgb(0, 54, 219) !important;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .newbannerServiceSetction-inner-div {
        margin: 1rem 0;
        /* display: flex; */
        gap: 1rem;
    }

    .newbannerServiceSetction-inner-div h3 {
        width: 20%;
        margin: 0;
        margin-bottom: 0rem;
        font-size: 1.5rem;
        /* font-weight: bolder; */
        color: #1012a2;
    }

    .newbanner-right-div {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

    }

    .newbanner-right-innerdiv .newbannerServiceSetction .newbannerServiceSetction-inner-div:nth-of-type(3) {
        margin-bottom: 0;
    }

}



@media (max-width: 980px) {
    .newbanner-inner-div {
        width: 100%;
        padding: 0 3%;
        background-color: white;
    }

    .newbanner-right-div {
        margin-bottom: 1rem;
        /* display: block; */
        align-items: center;
    }

    .newbanner-left-div {
        height: 45vh;
        display: flex;
        align-items: end;
        justify-content: left;
        padding: 7rem 0 2rem;
    }
}

@media (max-width: 780px) {

    .newtopHeaderHeading {
        font-size: 1.5rem;
        margin-bottom: 0rem;
    }

    .newimplemantationDomHeading {
        color: rgb(0, 54, 219) !important;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .newbanner-row-div {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        height: 100%;
    }

    .newbanner-right-div {
        margin-bottom: 1rem;
        /* display: block; */
        /* align-items: center; */
    }

    .newbanner-inner-div {
        height: 85vh;
    }

    .newbanner-right-div {
        height: 50%;
    }

    .newbanner-left-div {
        height: 50%;
        padding: 7rem 0 0 0;
    }

    .newbtns {
        margin: 0.5rem 0rem 0.5rem 0;
        padding: 0.8rem 3rem;
        width: 100%;
        background: #1012a2;
        color: black;
        border: none;
        border-radius: 0.2rem;
        font-weight: 500;
    }

    .HeadingSpanClass {
        color: rgb(16 18 162);
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
    }

    .newtopHeaderHeading {
        font-size: 1.5rem;
        margin-bottom: 0rem;
        line-height: normal;
    }
}

@media (max-width: 360px) {
    .newtopHeaderHeading {
        font-size: 1rem;
        margin-bottom: 0rem;
        line-height: normal;
    }

    .HeadingSpanClass {

        font-size: 1rem;
        line-height: normal;
    }

    .newbtns {
        margin: 0.2rem 0rem 0.5rem 0;
        padding: 0.5rem 3rem;
        width: 100%;
    }

    .newbanner-right-innerdiv {
        padding: 0.5rem 1rem;
    }

    .newimplemantationDomHeading {
        color: rgb(0, 54, 219) !important;
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .newbannerServiceSetction-inner-div {
        display: flex;
    }

    .newbannerServiceSetction-inner-div h3 {
        width: 20%;
        margin: 0;
        margin-bottom: 0rem;
        font-size: 1rem;
        /* font-weight: bolder; */
        color: #1012a2;
    }

    .newbannerServiceSetction-inner-div {
        margin: 0.5rem 0;
    }
}

@media (max-width: 320px) {
    .newbanner-inner-div {
        height: auto;
    }
}