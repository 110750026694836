
body {
	scroll-behavior: smooth;
}



.contact_us {
	background: #eaeaea;
	padding: 120px 0px;
}

.contact_inner {
	background-color: #fff;
	position: relative;
	border-radius: 25px;
}

.contact_field {
	padding: 60px 340px 90px 100px;
}

@media only screen and (max-width:995px) {
	.contact_field {
		padding: 60px;
	}

	.col-md-10 {
		margin: auto;
	}

	.col-md-2 {
		display: none;
	}
}

@media only screen and (max-width:425px) {
	.contact_field {
		padding: 1.5rem;
	}
}

.right_conatct_social_icon {
	height: 100%;
}

.contact_field h3 {
	color: #000;
	font-size: 40px;
	letter-spacing: 1px;
	font-weight: 600;
	margin-bottom: 10px
}

.contact_field p {
	color: #000;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 1px;
	margin-bottom: 35px;
}

.contact_field .form-control {
	border-radius: 0px;
	border: none;
	border-bottom: 1px solid #ccc;
}

.contact_field .form-control:focus {
	box-shadow: none;
	outline: none;
	border-bottom: 2px solid #1325e8;
}

.contact_field .form-control::placeholder {
	font-size: 13px;
	letter-spacing: 1px;
}

.contact_info_sec {
	position: absolute;
	background-color: #2346c0;
	right: 0px;
	top: 18%;
	height: 340px;
	width: 340px;
	padding: 40px;
	border-radius: 25px 0 0 25px;
}

@media only screen and (max-width: 995px) {
	.contact_info_sec {
		position: relative;
		align-items: center;
		margin-right: 20px;
		width: auto;
		border-radius: 25px;
		margin-right: 0;
	}
}

.contact_info_sec h4 {
	letter-spacing: 1px;
	padding-bottom: 15px;
	color: whitesmoke;
}

.info_single {
	margin: 30px 0px;
	color: aliceblue;
}

.info_single i {
	margin-right: 15px;
}

.info_single span {
	font-size: 14px;
}

.btn_contact.contact_form_submit {
	/* background: linear-gradient(to top right, #2d58ed -5%, #101240 100%);
	border: none;
	color: #fff;
	padding: 10px 15px;
	width: 100%;
	margin-top: 25px;
	border-radius: 35px;
	cursor: pointer;
	font-size: 14px;
	letter-spacing: 2px; */
	border: none;
    color: #fff;
    padding: 10px 15px;
    width: 100%;
    margin-top: 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    /* letter-spacing: 2px;*/
	    background-color: #2346c0;
}

.socil_item_inner li {
	list-style: none;
}

.socil_item_inner li a {
	color: #fff;
	margin: 0px 15px;
	font-size: 14px;
}

.socil_item_inner {
	padding-bottom: 10px;
}

.map_sec {
	padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
	color: #000;
	text-align: center
}

.map_inner p {
	font-size: 13px;
}

.map_bind {
	margin-top: 50px;
	border-radius: 30px;
	overflow: hidden;
}

.email_link,
.email_link:hover {
	color: white;
	border: none !important;
	text-decoration: none !important;
}