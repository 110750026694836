   .activity-stream {
    list-style-type: none;
    margin: 1rem 0.5rem;
    padding: 0;
    border-left: 1px solid #ccc;
    padding-left: 1.5em;
}

.activity-stream li {
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin: 1em;
    display: block;
    position: relative;
}

.activity-stream li .icon {
    height: 30px;
    width: 30px;
    color: #fff;
    box-sizing: border-box;
    background: #1011a2;
    position: absolute;
    left: -3.5em;
    top: 0.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9;
    color: white;
}


.activity-stream li:before,
.activity-stream li:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-left: 0;
}

.activity-stream li:before {
    top: 1em;
    left: -8px;
    border-right-color: #aaa;
    border-width: 7px;
}

.activity-stream li:after {
    top: 1em;
    left: -7px;
    border-right-color: white;
    border-width: 7px;
}
.historyCardDiv{
    color:'black';

}
.historyCardDiv p{
    margin: 0 ;
}
.heding-historyCard{
    font-size: 0.9rem;
    font-weight: bold;
    
    margin: 0;
}
.history-ChangesHeading{
    display: flex;
    gap: 1rem;
}
.history-ChangesHeading p{
    font-size: 0.9rem;
    color: black;
}
.history-ChangesHeading svg{
   height: 1rem;
   width: 1rem;
}
.history-ChangesHeading{
    margin: 0;
}
.historyTimeDateP{
    font-size: 0.8rem;
    text-align: end;
}
.icon{
   
}