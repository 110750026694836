.assignToLogoStyle:hover .tooltip{
    position: absolute;
    bottom: 0;
    display: block;
    height: 1rem;
    font-size: 0.9rem;
    background-color: red;
}

.tooltip2 {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #44546f;
      color: white;
      text-align: center;
      border-radius: 5px;
      padding: 4px 5px;
      font-size: 0.65rem;
      z-index: 1;
      white-space: nowrap;
}

.assignToLogoStyle:hover .tooltip2 {
    display: block;
}
.historyAssignToLogoStyle{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-size: 0.8rem;
    color: white;
    /* background-color: #b5b0b0; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    position: relative;
}
.historyTooltip2{
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #44546f;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 4px 5px;
    font-size: 0.65rem;
    z-index: 1;
    white-space: nowrap;
}
.historyAssignToLogoStyle:hover .historyTooltip2 {
    display: block;
}

.assignToLogoStyleNew{
 
    border-radius: 50%;
    font-size: 0.8rem;
    color: white;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative
}
.assignToLogoStyleNew:hover .tooltip2 {
    display: block;
}
.assignToLogoStyleNew:hover .tooltipNewLeftNewRight {
    display: block;
}
.assignToLogoStyleNew:hover .tooltipNewLeft {
    display: block;
}
.tooltipNewLeft {
    display: none;
    position: absolute;
    top: 100%;
    /* right: 0; */
    left: 0;
    background-color: #44546f;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 4px 5px;
    font-size: 0.65rem;
    z-index: 1;
    white-space: nowrap;
}
.tooltipNewLeftNewRight {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;

    background-color: #44546f;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 4px 5px;
    font-size: 0.65rem;
    z-index: 1;
    white-space: nowrap;
}
.colorClassHeading{
    color:#1011a2;
}